import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "./firebase";
import { generateUuid } from "./firebase.helpers";
import { toast } from "react-hot-toast";

const getCalendarEvents = async (praxisId) => {
  /** Fetching remote data from Firestore */
  // // debugger;
  try {
    const calendarCollectionRef = query(
      collection(db, "calendar"),
      where("praxisId", "==", praxisId)
    );
    const querySnapshot = await getDocs(calendarCollectionRef);

    if (!querySnapshot.empty) {
      // Process the data
      const events = querySnapshot.docs.map((doc) => doc.data());
      return events;
    } else {
      console.log("No documents found in the 'calendar' collection.");
      // Handle this case as needed
    }
  } catch (error) {
    console.error("Error fetching remote events: ", error);
    throw error;
  }
};

const addOrUpdateCalendarEvent = async (event, action, praxisId) => {
  /**
   * Make sure to return 4 mandatory fields:
   * event_id: string|number
   * title: string
   * start: Date|string
   * end: Date|string
   * ....extra other fields depend on your custom fields/editor properties
   */
  try {
    if (action === "edit") {
      // // debugger;
      const calendarDocRef = doc(db, "calendar", event.event_id);
      await updateDoc(calendarDocRef, event);
    } else if (action === "create") {
      // // debugger;
      const calendarCollectionRef = collection(db, "calendar");
      event.start = new Date(event.start);
      event.end = new Date(event.end);
      event.praxisId = praxisId;
      if (event.isConstant) {
        // If the event repeats, create and add multiple documents based on the repetition logic
        const repetitions = generateRecurringEvents(event);
        repetitions.map(async (e) => {
          await addDoc(calendarCollectionRef, e);
        });
        const allNewEvents = await getCalendarEvents(praxisId);
        return allNewEvents;
      } else {
        debugger;
        // If it's a one-time event, add a single document
        const newEventDocRef = await setDoc(
          doc(db, "calendar", event.event_id),
          event
        );
        const allNewEvents = await getCalendarEvents(praxisId);
        return allNewEvents;
      }
    }

    return {
      ...event,
      event_id: event.event_id || Math.random(),
    };
  } catch (error) {
    console.error("Error handling confirm action: ", error);
    toast.error("Hubo un error al agregar/actualizar un calendario");
    throw error;
  }
};

const generateRecurringEvents = (event) => {
  const { start, end, event_id, howOften } = event;
  const interval = howOften;

  const generatedEvents = [];

  switch (interval) {
    // case 1: // Monday to Friday
    //   for (let i = 0; i < 60; i++) {
    //     const startDate = new Date(start);
    //     startDate.setDate(startDate.getDate() + i * 7); // Increment by one week
    //     const endDate = new Date(end);
    //     endDate.setDate(endDate.getDate() + i * 7);
    //     const newEvent_id = generateUuid();
    //     generatedEvents.push({
    //       ...event,
    //       start: startDate,
    //       end: endDate,
    //       event_id: newEvent_id,
    //     });
    //   }
    //   break;

    // case 2: // Saturdays and Sundays
    //   for (let i = 0; i < 24; i++) {
    //     const startDate = new Date(start);
    //     startDate.setDate(startDate.getDate() + 7); // Increment by one week
    //     const endDate = new Date(end);
    //     endDate.setDate(endDate.getDate() + 7);
    //     let newEvent_id = generateUuid();
    //     generatedEvents.push({
    //       ...event,
    //       start: startDate,
    //       end: endDate,
    //       event_id: newEvent_id,
    //     });
    //     // Add an additional event for Sunday
    //     const sundayStartDate = new Date(startDate);
    //     sundayStartDate.setDate(sundayStartDate.getDate() + 1);
    //     const sundayEndDate = new Date(endDate);
    //     sundayEndDate.setDate(sundayEndDate.getDate() + 1);
    //     newEvent_id = generateUuid();
    //     generatedEvents.push({
    //       ...event,
    //       start: startDate,
    //       end: endDate,
    //       event_id: newEvent_id,
    //     });
    //   }
    //   break;

    case 3: // Every Monday
      for (let i = 0; i < 12; i++) {
        const startDate = new Date(start);
        startDate.setDate(startDate.getDate() + 7 * i); // Increment based on the specified interval
        const endDate = new Date(end);
        endDate.setDate(endDate.getDate() + 7 * i);
        const newEvent_id = generateUuid();
        generatedEvents.push({
          ...event,
          start: startDate,
          end: endDate,
          event_id: newEvent_id,
        });
      }
      break;
    case 4: // Every Tuesday
      for (let i = 0; i < 12; i++) {
        const startDate = new Date(start);
        startDate.setDate(startDate.getDate() + 7 * i); // Increment based on the specified interval
        const endDate = new Date(end);
        endDate.setDate(endDate.getDate() + 7 * i);
        const newEvent_id = generateUuid();
        generatedEvents.push({
          ...event,
          start: startDate,
          end: endDate,
          event_id: newEvent_id,
        });
      }
      break;
    case 5: // Every Wednesday
      for (let i = 0; i < 12; i++) {
        const startDate = new Date(start);
        startDate.setDate(startDate.getDate() + 7 * i); // Increment based on the specified interval
        const endDate = new Date(end);
        endDate.setDate(endDate.getDate() + 7 * i);
        const newEvent_id = generateUuid();
        generatedEvents.push({
          ...event,
          start: startDate,
          end: endDate,
          event_id: newEvent_id,
        });
      }
      break;
    case 6: // Every Thusday
      for (let i = 0; i < 12; i++) {
        const startDate = new Date(start);
        startDate.setDate(startDate.getDate() + 7 * i); // Increment based on the specified interval
        const endDate = new Date(end);
        endDate.setDate(endDate.getDate() + 7 * i);
        const newEvent_id = generateUuid();
        generatedEvents.push({
          ...event,
          start: startDate,
          end: endDate,
          event_id: newEvent_id,
        });
      }
      break;
    case 7: // Every Friday
      for (let i = 0; i < 12; i++) {
        const startDate = new Date(start);
        startDate.setDate(startDate.getDate() + 7 * i); // Increment based on the specified interval
        const endDate = new Date(end);
        endDate.setDate(endDate.getDate() + 7 * i);
        const newEvent_id = generateUuid();
        generatedEvents.push({
          ...event,
          start: startDate,
          end: endDate,
          event_id: newEvent_id,
        });
      }
      break;
    case 8: // Every Saturday
      for (let i = 0; i < 12; i++) {
        const startDate = new Date(start);
        startDate.setDate(startDate.getDate() + 7 * i); // Increment based on the specified interval
        const endDate = new Date(end);
        endDate.setDate(endDate.getDate() + 7 * i);
        const newEvent_id = generateUuid();
        generatedEvents.push({
          ...event,
          start: startDate,
          end: endDate,
          event_id: newEvent_id,
        });
      }
      break;

    // case 9: // Never (single occurrence)
    //   generatedEvents.push({ ...event });
    //   break;

    default:
      // Handle other scenarios if needed
      break;
  }

  return generatedEvents;
};

const deleteEventHandler = async (event_id, praxisId) => {
  const calendarRef = doc(db, "calendar", event_id);

  try {
    const calendarDocSnapshot = await getDoc(calendarRef);
    // debugger;
    if (!calendarDocSnapshot.exists()) {
      toast.error("Calendar document not found");
      return false;
    }
    await updateDoc(calendarRef, { isActive: false });
    toast.success("Calendar Event deleted successfully");

    let allNewEvents = await getCalendarEvents(praxisId);
    allNewEvents = allNewEvents.filter((e) => e.isActive == true);
    allNewEvents = allNewEvents.map((event) => {
      event.start = new Date(event.start.toDate());
      event.end = new Date(event.end.toDate());
      return event;
    });
    return allNewEvents;
  } catch (error) {
    toast.error("Error deleting calendar entry:", error);
    return false;
  }
};

export { getCalendarEvents, addOrUpdateCalendarEvent, deleteEventHandler };
