import React, { useState, useEffect } from "react";
import { getData } from "../../firebase/firebase.patient";
import { useDispatch, useSelector } from "react-redux";
import PatientsTable from "../patient/patientsDisplay/PatientsTable";
import Navbar from "../navbar/Navbar";
import { setAllPatients } from "../../store/patients/patients.reducer";
import { praxisIdAugmenterScript } from "../../firebase/firebase";

const Home = () => {
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [selectedView, setSelectedView] = useState("dashboard");
  const [praxisId, setPraxisId] = useState(null);
  const localPraxis = useSelector((state) => state.user?.userDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    let getNewData = async () => {
      if (praxisId) {
        let data = await getData(praxisId);
        if (data !== undefined) {
          setData(data);
          setFilteredData(data);
          dispatch(setAllPatients(data));
        }
      }
    };
    getNewData();
  }, [praxisId]);

  useEffect(() => {
    if (localPraxis !== null && praxisId == null) {
      setPraxisId(localPraxis.praxisId);
    }
  }, [localPraxis]);

  return (
    <>
      <Navbar>
        <PatientsTable userData={filteredData} />
      </Navbar>
    </>
  );
};

export default Home;
