import React from "react";

const WizardControllers = ({
  steps,
  prevStep,
  nextStep,
  currentStep,
  children,
  canContinue,
}) => {
  return (
    <div className={`mt-2`}>
      <div className="w-full flex justify-center my-5">
        <ol className="mx-8 flex items-center space-x-5">
          {steps.map((step, i) => (
            <li key={`step_${i}`}>
              {step.status === "complete" ? (
                <a
                  href={step.href}
                  className="block w-2.5 h-2.5 bg-indigo-600 rounded-full hover:bg-indigo-900"
                >
                  <span className="sr-only"></span>
                </a>
              ) : step.status === "current" ? (
                <a
                  href={step.href}
                  className="relative flex items-center justify-center"
                  aria-current="step"
                >
                  <span
                    className="absolute w-5 h-5 p-px flex"
                    aria-hidden="true"
                  >
                    <span className="w-full h-full rounded-full bg-indigo-200" />
                  </span>
                  <span
                    className="relative block w-2.5 h-2.5 bg-indigo-600 rounded-full"
                    aria-hidden="true"
                  />
                  <span className="sr-only"></span>
                </a>
              ) : (
                <a
                  href={step.href}
                  className="block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400"
                >
                  <span className="sr-only"></span>
                </a>
              )}
            </li>
          ))}
        </ol>
      </div>
      <div className="h-60vh overflow-y-scroll">{children}</div>
      {canContinue && (
        <nav
          className="flex items-center justify-evenly w-full"
          aria-label="Progress"
        >
          <button
            type="button"
            disabled={currentStep === 0}
            onClick={() => prevStep()}
            className="p-1 m-1 bg-gray-400 w-1/12 border rounded rounded-md text-white"
          >
            Prev
          </button>

          <button
            type="button"
            disabled={currentStep === 3}
            onClick={() => nextStep()}
            className="p-1 m-1 bg-gray-400 w-1/12 border rounded rounded-md text-white"
          >
            Next
          </button>
        </nav>
      )}
    </div>
  );
};

export default WizardControllers;
