import React from "react";
import ReminderButton from "../../../UI/ReminderButton";

const PatientStats = ({ stats }) => {
  console.log(stats);
  return (
    <div className="mx-auto h-full">
      <div className="w-full">
        <div className="p-5 flex items-center w-full h-[20vh] justify-between">
          <div className="" style={{ width: "-webkit-fill-available" }}>
            <div className="flex justify-center flex-col items-center">
              <div className="relative inline-flex items-center justify-center w-14 h-14 bg-red-600 rounded-full overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                <span className="font-medium text-gray-600 dark:text-gray-300">
                  {stats.personalDetails?.name.split(" ")[0][0] +
                    stats.personalDetails?.name.split(" ")[1][0]}
                </span>
              </div>
              <div>{stats.personalDetails?.name}</div>
            </div>
          </div>
          <div className="flex h-full">
            <div className="flex flex-col justify-between p-3 border rounded w-[25vw]">
              <div className="flex p-1 text-ellipsis overflow-hidden">
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-3 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                    />
                  </svg>
                  <p className="text-sm text-gray-800 ml-2">RUC:</p>
                </div>
                <p className="text-sm text-deep-purple-accent-400 ml-2">
                  {/* {stats.personalDetails?.personalAddress} */}
                  34567890-2
                </p>
              </div>

              <div className="flex p-1 text-ellipsis overflow-hidden">
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-3 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
                    />
                  </svg>

                  <p className="text-sm text-gray-800 ml-2">Razon Social:</p>
                </div>
                <p className="text-sm text-deep-purple-accent-400 ml-2">
                  {/* {stats.personalDetails?.personalAddress} */}
                  Pepito SRL
                </p>
              </div>

              <div className="flex p-1 text-ellipsis overflow-hidden">
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-3 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                    />
                  </svg>

                  <p className="text-sm text-gray-800 ml-2">Naciemiento:</p>
                </div>
                <p className="text-sm text-deep-purple-accent-400 ml-2">
                  {stats.personalDetails?.birthdate}
                </p>
              </div>
            </div>

            <div className="flex flex-col justify-between p-3 border rounded text-ellipsis overflow-hidden">
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-3 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                  />
                </svg>

                <p className="text-sm m-1 text-gray-800 ml-2">Telefono:</p>
                <p className="text-sm m-1 text-deep-purple-accent-400">
                  {stats.personalDetails?.tel}
                </p>
              </div>
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-3 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H6.911a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661Z"
                  />
                </svg>

                <p className="text-sm m-1 text-gray-800 ml-2">Email:</p>
                <p className="text-sm m-1 text-deep-purple-accent-400">
                  {stats.personalDetails?.email}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientStats;
