import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
} from "firebase/firestore";

// OLD DEV
// const firebaseConfigDEV = {
//   apiKey: "AIzaSyCXuoJDMEDoUzptel3X2dvF4DIOvXo5e7E",
//   authDomain: "dentistclientapp.firebaseapp.com",
//   databaseURL: "https://dentistclientapp-default-rtdb.firebaseio.com",
//   projectId: "dentistclientapp",
//   storageBucket: "dentistclientapp.appspot.com",
//   messagingSenderId: "486884016030",
//   appId: "1:486884016030:web:acb62ca7c23cae9ffce08f",
//   measurementId: "G-S5LMMWH1ZH",
// };

// New DEV
const firebaseConfigNewDEV = {
  apiKey: "AIzaSyBJ47dzykxLlVu1z4u1suyuI9CXHVriZ9E",
  authDomain: "odontostarkdev.firebaseapp.com",
  projectId: "odontostarkdev",
  storageBucket: "odontostarkdev.appspot.com",
  messagingSenderId: "142682552638",
  appId: "1:142682552638:web:8ba5e7909eef8787ab146f",
  measurementId: "G-N03S4BMXCW",
};

// // QA
const firebaseConfigQA = {
  apiKey: "AIzaSyCU0ITSyPGByRl8ksIGwPAUXWrLiinVZJ0",
  authDomain: "odontostark-ae799.firebaseapp.com",
  projectId: "odontostark-ae799",
  storageBucket: "odontostark-ae799.appspot.com",
  messagingSenderId: "613552123000",
  appId: "1:613552123000:web:e105b25d76e9d9060b118d",
  measurementId: "G-Z0V7TCY11R",
};

// // PROD
const firebaseConfigPROD = {
  apiKey: "AIzaSyCSjvneZ6vlabDcVv1FTbb3P0LVW7CvYfU",
  authDomain: "odontostark-66ab6.firebaseapp.com",
  projectId: "odontostark-66ab6",
  storageBucket: "odontostark-66ab6.appspot.com",
  messagingSenderId: "281864961848",
  appId: "1:281864961848:web:ceda0a2e94086424b2cbcd",
  measurementId: "G-FQNM3T1C57",
};

// const app = initializeApp(firebaseConfig, "main");
const appAuth = initializeApp(firebaseConfigQA);
const auth = getAuth(appAuth);
const db = getFirestore(appAuth);

// ======

//   =====
// Very dangerous - do not use unless mockupda data is needed
// const postEmptyObject = async (mockupData) => {
//     try {
//         await doc(db2, "clients", mockupData);
//         debugger
//         mockupData.map(async e => {await addDoc(collection(db2, "clients"), e);})

//       console.log('Clients collection updated with mockup data');
//     } catch (error) {
//       console.error('Error updating clients collection:', error.message);
//     }
//   };

// ============ = = DANGER = = ==============
const praxisIdAugmenterScript = async (praxisId) => {
  try {
    debugger;
    const data = [];
    const q = query(
      collection(db, "patients")
      // where("praxisId", "==", praxisId)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (doc) => {
      // debugger;
      let newDoc = doc.data();
      newDoc.praxisId = [doc.data().praxisId];
      console.log(newDoc);
      data.push(newDoc);
    });
    data.map(async (e) => {
      debugger;
      await updateDoc(doc(db, "patients", e.id), e);
    });

    return data;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error; // Re-throw the error if needed
  }
};

export { auth, db, praxisIdAugmenterScript };
