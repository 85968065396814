import DatePicker from "react-datepicker";
import React, { useEffect, useState } from "react";
import Dropdown from "../../UI/Dropdown";
import { DatePickerIcon } from "../../UI/DatePickerIcon";

const PatientChecks = ({ odontologicPast, pastSetter }) => {
  const [lastMedicalCheck, setLastMedicalCheck] = useState("");
  const [reasonForLosingTheet, setReasonForLosingTheet] = useState("");
  const [reasonForLosingTheetText, setReasonForLosingTheetText] = useState("");
  const [mouthHygiene, setMouthHygiene] = useState("");
  const [lastVisit, setLastVisit] = useState(new Date());

  const odontologicPastObj = {
    lastMedicalCheck: lastMedicalCheck,
    reasonForLosingTheet: reasonForLosingTheet,
    reasonForLosingTheetText: reasonForLosingTheetText,
    mouthHygiene: mouthHygiene,
    lastVisit: `${new Date(lastVisit)}`,
  };

  useEffect(() => {
    pastSetter(odontologicPastObj);
  }, [
    lastMedicalCheck,
    reasonForLosingTheet,
    reasonForLosingTheetText,
    mouthHygiene,
    lastVisit,
  ]);

  return (
    <div className="medicalDetails md:w-6/12 sm:w-screen">
      <div className="px-5 pb-5">
        <div>
          <Dropdown
            data={[
              { title: "Menos", id: 1 },
              { title: "6 Meses", id: 2 },
              { title: "1 Año", id: 3 },
              { title: "Más", id: 4 },
            ]}
            title="Fecha de su ultima consulta odontológica"
            currentValueSetter={setLastMedicalCheck}
            //   disabled={!editModeState}
            defaultValue={"Menos"}
          />
        </div>
        <div className="mt-3">
          <Dropdown
            data={[
              { title: "Caries ", id: 1 },
              { title: "Accidente", id: 2 },
              { title: "Movilidad", id: 3 },
              { title: "Otros", id: 4 },
              { title: "No hubo perdida de dientes", id: 5 },
            ]}
            title="La perdida de sus dientes fue por"
            currentValueSetter={setReasonForLosingTheet}
            //   disabled={!editModeState}
            defaultValue={"Caries"}
          />
        </div>
        {reasonForLosingTheet == "Otros" && (
          <div className="flex flex-col mt-3 ml-5">
            <label className="text-sm">Razon</label>
            <input
              onChange={(e) => setReasonForLosingTheetText(e.target.value)}
              defaultValue={reasonForLosingTheetText}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
        )}
        <div className="mt-3">
          <Dropdown
            data={[
              { title: "Adecuada", id: 1 },
              { title: "Deficiente", id: 2 },
            ]}
            title="Higiene bucal"
            currentValueSetter={setMouthHygiene}
            //   disabled={!editModeState}
            defaultValue={"Adecuada"}
          />
        </div>
        <div className="mt-3">
          <label className="text-sm">Ultima Visita</label>
          <div className="relative max-w-sm">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <DatePicker
              selected={lastVisit}
              onChange={(date) => setLastVisit(date)}
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
              popperPlacement="top"
              showIcon={true}
              icon={DatePickerIcon}
            />
          </div>
        </div>
      </div>
      {/* <div className="flex flex-row">
        {editMode !== undefined && (
          <div className="flex flex-row-reverse p-3">
            <div className="flex-initial pl-1">
              <button
                onClick={() => setEditModeState(!editModeState)}
                type="button"
                className="flex items-center px-5 py-2.5 font-medium tracking-wide text-white capitalize   bg-black rounded-md hover:bg-gray-800  focus:outline-none focus:bg-gray-900  transition duration-300 transform active:scale-95 ease-in-out"
              >
                {!editModeState ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-pencil"
                    viewBox="0 0 16 16"
                    id="IconChangeColor"
                  >
                    {" "}
                    <path
                      d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
                      id="mainIconPathAttribute"
                      fill="#ffffff"
                    ></path>{" "}
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-eye"
                    viewBox="0 0 16 16"
                    id="IconChangeColor"
                  >
                    {" "}
                    <path
                      d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                      id="mainIconPathAttribute"
                    ></path>{" "}
                    <path
                      d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                      id="mainIconPathAttribute"
                    ></path>{" "}
                  </svg>
                )}
                <span className="mx-1">{!editModeState ? "Edit" : "View"}</span>
              </button>
            </div>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default PatientChecks;
