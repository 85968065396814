import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { useEffect } from "react";
import { getActivePaymentsByUserId } from "../../firebase/firebase.payment";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ServicesDropdown({ userId, setSelectedService }) {
  const [currentUId, setCurrentUId] = useState(null);
  const [allPayments, setAllPayments] = useState(null);
  const [selected, setSelected] = useState(null);

  const setValue = (option) => {
    setSelected(option);
    console.log(option);
    setSelectedService(option);
  };

  useEffect(() => {
    const getPayments = async () => {
      let payments = await getActivePaymentsByUserId(userId);
      setAllPayments(payments);
      if (payments?.length > 0) {
        debugger;
        setSelected(payments[0]);
        setSelectedService(payments[0]);
      } else {
        debugger;
        setSelected(null);
        setSelectedService(null);
      }
    };
    if (userId !== currentUId) {
      setCurrentUId(userId);
      getPayments(userId);
    }
  }, [userId]);

  useEffect(() => {
    debugger;
    if (allPayments?.length == 0) {
      debugger;
      setAllPayments(null);
    }
  }, [allPayments]);

  return (
    <>
      {allPayments?.length > 0 ? (
        <Listbox value={selected} disabled={false}>
          {({ open }) => (
            <>
              <div className="relative p-2 flex w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                <Listbox.Button className="w-full flex border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0">
                  <span className="flex items-center">
                    <span className="ml-3 block truncate">
                      {selected?.reason}
                    </span>
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Listbox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Listbox.Button>
              </div>
              <div className="relative mt-1">
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {allPayments?.length > 0 &&
                      allPayments.map((option) => {
                        if (!option.hasBeenPaid) {
                          return (
                            <Listbox.Option
                              key={option.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "text-white bg-indigo-600"
                                    : "text-gray-900",
                                  "relative cursor-default select-none py-2 pl-3 pr-9"
                                )
                              }
                              value={option.title}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div
                                    className="flex items-center"
                                    onClick={() => setValue(option)}
                                  >
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "ml-3 block truncate"
                                      )}
                                    >
                                      {option.reason}
                                    </span>
                                  </div>
                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-white"
                                          : "text-indigo-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          );
                        }
                      })}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      ) : (
        <div>No existen servicios activos</div>
      )}
    </>
  );
}
