import React, { useEffect, useState } from "react";
import {
  registerAdmin,
  registerOwner,
  registerUser,
} from "../../firebase/firebase.user";
import Navbar from "../navbar/Navbar";
import DatePicker from "react-datepicker";
import { DatePickerIcon } from "../UI/DatePickerIcon";
import { getAllPraxises } from "../../firebase/firebase.admin";

export const Register = () => {
  // Praxis
  const [praxisName, setPraxisName] = useState("");
  const [praxisAddress, setPraxisAddress] = useState("");
  const [praxisPhone, setPraxisPhone] = useState("");

  const [paymentDate, setPaymentDate] = useState(new Date());
  const [startOfLicense, setStartOfLicense] = useState(new Date());
  const [licenseType, setLicenseType] = useState("");
  const [price, setPrice] = useState("");
  const [paid, setPaid] = useState(true);
  const [invoice, setInvoice] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  // User
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  // const [password, setPassword] = useState(undefined);
  const [role, setRole] = useState("owner");
  const [allPraxises, setAllPraxises] = useState([]);
  const [praxis, setPraxis] = useState(null);

  useEffect(() => {
    const fetchAllPraxises = async () => {
      const praxises = await getAllPraxises();
      setAllPraxises(praxises);
    };
    fetchAllPraxises();
  }, []);

  const praxisOwner = {
    user: {
      // id: authUser.user.uid, // Use the Firebase Authentication user ID as the document ID
      name: name,
      lastname: lastname,
      phone: phone,
      praxisPhone: praxisPhone,
      email: email,
      praxis: null,
      address: address,
      role: role,
      praxisId: null,
    },
    praxis: {
      praxisName: praxisName,
      address: praxisAddress,
      praxisPhone: praxisPhone,
      createdAt: new Date().toISOString(),
      payment: {
        paid: paid,
        membershipStart: startOfLicense,
        amount: price,
        paymentDate: paymentDate,
        licenseType: licenseType,
        invoice: invoice,
        additionalInfo: additionalInfo,
      },
    },
  };

  const userData = {
    user: {
      // id: authUser.user.uid, // Use the Firebase Authentication user ID as the document ID
      name: name,
      lastname: lastname,
      phone: phone,
      praxisPhone: praxisPhone,
      email: email,
      praxis: praxis?.praxisName,
      address: address,
      role: role,
      praxisId: praxis?.id,
    },
    praxis: praxis,
  };

  const adminAccount = {
    user: {
      // id: authUser.user.uid, // Use the Firebase Authentication user ID as the document ID
      name: name,
      lastname: lastname,
      phone: phone,
      praxisPhone: praxisPhone,
      email: email,
      address: address,
      role: role,
    },
  };

  const handleCreation = async () => {
    let registeredUser;
    switch (role) {
      case "owner":
        registeredUser = await registerOwner(praxisOwner);
      case "admin":
        registeredUser = await registerAdmin(adminAccount);
      case "user":
        registeredUser = await registerUser(userData);
    }
  };

  return (
    <div>
      <Navbar>
        <main className="bg-slate-600">
          <div className="mx-auto max-w-7xl py-6 sm:px-6 md:px-6 lg:px-6">
            {/* Replace with your content */}
            <div className="px-4 sm:px-0">
              <div
                className="bg-white rounded-lg shadow"
                style={{ height: "84vh", overflow: "scroll" }}
              >
                <div className="flex min-h-full items-center justify-center py-2 px-4 sm:px-6 lg:px-8">
                  <div className="w-full space-y-8 mt-2">
                    <div className="">
                      <div className="">
                        <div className="border-b border-gray-900/10 pb-12">
                          <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Profile
                          </h2>
                          <p className="mt-1 text-sm leading-6 text-gray-600">
                            This information will be displayed publicly so be
                            careful what you share.
                          </p>
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            htmlFor="country"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Tipo de Cuenta
                          </label>
                          <div className="mt-2">
                            <select
                              id="country"
                              name="country"
                              autoComplete="country-name"
                              className="block w-full p-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                              onChange={(e) => setRole(e.target.value)}
                            >
                              <option value="owner">Dueño</option>
                              <option value="user">Usuario</option>
                              <option value="admin">Admin</option>
                            </select>
                          </div>
                        </div>

                        {role && role === "owner" && (
                          <div className="Applicant my-5">
                            <div className="border-b border-gray-900/10 pb-12">
                              <h2 className="text-base font-semibold leading-7 text-gray-900">
                                Información de la Praxis
                              </h2>
                              <p className="mt-1 text-sm leading-6 text-gray-600">
                                Use a permanent address where you can receive
                                mail.
                              </p>

                              <div>
                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                  <div className="sm:col-span-2">
                                    <label
                                      htmlFor="PraxisName"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Nombre de la Praxis
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        required
                                        type="text"
                                        name="NombreDeLaPraxis"
                                        id="PraxisName"
                                        autoComplete="name"
                                        onChange={(e) =>
                                          setPraxisName(e.target.value)
                                        }
                                        value={praxisName}
                                        className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-2">
                                    <label
                                      htmlFor="praxisPhone"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Telefono de la Praxis
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        required
                                        type="text"
                                        name="praxisPhone"
                                        id="praxisPhone"
                                        autoComplete="tel"
                                        value={praxisPhone}
                                        onChange={(e) =>
                                          setPraxisPhone(e.target.value)
                                        }
                                        className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-2">
                                    <label
                                      htmlFor="praxisAddresss"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Dirección de la Praxis
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        required
                                        id="praxisAddresss"
                                        name="praxisAddresss"
                                        type="address"
                                        autoComplete="address"
                                        value={praxisAddress}
                                        onChange={(e) =>
                                          setPraxisAddress(e.target.value)
                                        }
                                        className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="PraxisName"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Tipo de Licencia
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          required
                                          type="text"
                                          name="NombreDeLaPraxis"
                                          id="PraxisName"
                                          autoComplete="name"
                                          onChange={(e) =>
                                            setLicenseType(e.target.value)
                                          }
                                          value={licenseType}
                                          className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="praxisPhone"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Precio
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          required
                                          type="text"
                                          name="praxisPhone"
                                          id="praxisPhone"
                                          autoComplete="tel"
                                          value={price}
                                          onChange={(e) =>
                                            setPrice(e.target.value)
                                          }
                                          className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>

                                    <div className="sm:col-span-1 sm:col-end-8">
                                      <label
                                        htmlFor="PraxisName"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Inicio de licencia
                                      </label>
                                      <div className="mt-2">
                                        <DatePicker
                                          showIcon
                                          placeholder="Inicio de Licencia"
                                          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                                          popperPlacement="top"
                                          icon={DatePickerIcon}
                                          selected={startOfLicense}
                                          onChange={(date) =>
                                            setStartOfLicense(date)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="checkbox"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Pagado
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          required
                                          id="praxisAddresss"
                                          name="praxisAddresss"
                                          type="checkbox"
                                          autoComplete="address"
                                          value={paid}
                                          onChange={(e) =>
                                            setPaid(e.target.checked)
                                          }
                                          // className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="praxisAddresss"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Factura
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          required
                                          id="praxisAddresss"
                                          name="praxisAddresss"
                                          type="address"
                                          autoComplete="address"
                                          value={invoice}
                                          onChange={(e) =>
                                            setInvoice(e.target.value)
                                          }
                                          className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>

                                    <div className="sm:col-span-1 sm:col-end-8">
                                      <label
                                        htmlFor="praxisAddresss"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Fecha de Pago
                                      </label>
                                      <div className="mt-2">
                                        <DatePicker
                                          showIcon
                                          placeholder="Ultima Visita"
                                          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                                          popperPlacement="top"
                                          icon={DatePickerIcon}
                                          selected={paymentDate}
                                          onChange={(date) =>
                                            setPaymentDate(date)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-span-full">
                                      <label
                                        htmlFor="about"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Información adicional
                                      </label>
                                      <div className="mt-2">
                                        <textarea
                                          id="about"
                                          name="about"
                                          rows={3}
                                          onChange={(e) =>
                                            setAdditionalInfo(e.target.value)
                                          }
                                          value={additionalInfo}
                                          placeholder="Especificar canal y froma de venta | Se applica prueba gratis? | Early Adaptors?"
                                          className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                          // defaultValue={""}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* User */}
                        <div className="Applicant my-5">
                          <div className="border-b border-gray-900/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">
                              Información del Applicante
                            </h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">
                              Use a permanent address where you can receive
                              mail.
                            </p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first-name"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Nombre
                                </label>
                                <div className="mt-2">
                                  <input
                                    required
                                    type="text"
                                    name="first-name"
                                    id="first-name"
                                    autoComplete="name"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="last-name"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Apellido
                                </label>
                                <div className="mt-2">
                                  <input
                                    required
                                    type="text"
                                    name="last-name"
                                    id="last-name"
                                    autoComplete="lastname"
                                    value={lastname}
                                    onChange={(e) =>
                                      setLastname(e.target.value)
                                    }
                                    className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="email"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Email address
                                </label>
                                <div className="mt-2">
                                  <input
                                    required
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="telefono"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Telefono
                                </label>
                                <div className="mt-2">
                                  <input
                                    required
                                    id="telefono"
                                    name="telefono"
                                    type="phone"
                                    autoComplete="phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>

                              {/* <div className=" flex w-full justify-around"> */}
                              <div className="col-span-2">
                                <label
                                  htmlFor="street-address"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Dirección Personal
                                </label>
                                <div className="mt-2">
                                  <input
                                    required
                                    type="text"
                                    name="street-address"
                                    id="street-address"
                                    autoComplete="street-address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>

                              <div className="col-span-2">
                                <label
                                  htmlFor="street-address"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Praxis
                                </label>
                                <div className="mt-2">
                                  <select
                                    className="block w-full p-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    onChange={(e) =>
                                      setPraxis(JSON.parse(e.target.value))
                                    }
                                  >
                                    {allPraxises &&
                                      allPraxises.map((e) => {
                                        return (
                                          <option value={JSON.stringify(e)}>
                                            {e.praxisName}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                          type="button"
                          className="text-sm font-semibold leading-6 text-gray-900"
                        >
                          Cancelar
                        </button>
                        <button
                          // type="submit"
                          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={handleCreation}
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Navbar>
    </div>
  );
};

export default Register;
