import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { currentRawDate } from "./firebase.helpers";
import { generateUuid } from "./firebase.helpers";
import { toast } from "react-hot-toast";
import { db } from "./firebase";

const updateOrAddPayment = async (
  userId,
  serviceId,
  data,
  modalSetter,
  allPaymentsSetter,
  setServiceIsRunning
) => {
  const paymentDocRef = doc(db, "payments", userId);
  try {
    const docSnap = await getDoc(paymentDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      //   const newId = generateUuid();
      //   let serviceToUpdate = null;
      //   if (data.services) {
      //     serviceToUpdate = data.services.find(
      //       (service) => service.id === serviceId.id
      //     );
      // }
      //   if (serviceToUpdate) {
      //     // Update payment
      //     serviceToUpdate.balance =
      //       serviceToUpdate.balance - paymentAmount.amount;
      //     if (serviceToUpdate.balance === 0) {
      //       serviceToUpdate.hasBeenPaid = true;
      //     }
      //     serviceToUpdate.payments.push({
      //       id: newId,
      //       date: paymentAmount.date,
      //       amount: paymentAmount.amount,
      //       invoice: paymentAmount.invoice,
      //     });
    } else {
      data.id = generateUuid();
      debugger;
      await setDoc(doc(db, "payments", data.id), data);
      // allPaymentsSetter(data.services);
      toast.success("Pago agregado exitosamente");
      modalSetter(false);

      // // Add payment
      // paymentAmount.id = newId;
      // paymentAmount.date = paymentAmount.date;
      // if (data.services !== undefined && data.services.length > 0) {
      //   data.services = [...data.services, paymentAmount];
      // } else {
      //   data.services = [paymentAmount];
    }
    // }
    // await updateDoc(doc(db, "patients", userId), data);
    // allPaymentsSetter(data.services);
    // toast.success("Pago agregado exitosamente");
    // modalSetter(false);
    // setServiceIsRunning(false);
    // } else {
    //   toast.error("Usuario no encontrado");
    // }
  } catch (err) {
    toast.error(
      "Hubo un problema al agregar el pago. Por favor inténtelo nuevamente."
    );
    console.log(err);
  }
};

const deletePaymentItem = async (
  userId,
  serviceId,
  // modalSetter,
  allPaymentsSetter
  // setServiceIsRunning
) => {
  const userDocRef = doc(db, "patients", userId);

  try {
    const docSnap = await getDoc(userDocRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      if (data.services) {
        // Find the index of the service to delete
        let isServiceIndex = data.services.findIndex(
          (service) => service.id === serviceId
        );

        let isPaymentIndex;
        let serviceIndex;
        if (isServiceIndex === -1) {
          data.services.map((e, i) => {
            let itemIndex = e.payments.findIndex(
              (service) => service.id === serviceId
            );
            if (itemIndex !== -1) {
              isPaymentIndex = itemIndex;
              serviceIndex = i;
            }
            // if (isPaymentIndex !== -1) {
            //   // serviceIndex = isPresent;
            // }
          });
        }

        if (isServiceIndex !== -1 || isPaymentIndex !== -1) {
          if (isServiceIndex !== -1) {
            // Delete the service
            data.services.splice(isServiceIndex, 1);
          }
          if (isPaymentIndex !== -1 && serviceIndex !== undefined) {
            data.services[serviceIndex].balance =
              data.services[serviceIndex].balance +
              data.services[serviceIndex].payments[isPaymentIndex].amount;

            if (data.services[serviceIndex].balance !== 0) {
              data.services[serviceIndex].hasBeenPaid = false;
            } else {
              data.services[serviceIndex].hasBeenPaid = true;
            }
            // Delete the service
            data.services[serviceIndex].payments.splice(isPaymentIndex, 1);
          }
          // Update the document
          await updateDoc(doc(db, "patients", userId), data);

          allPaymentsSetter(data.services);
          toast.success("Servicio eliminado exitosamente");
          // modalSetter(false);
          // setServiceIsRunning(false);
        } else {
          toast.error("Servicio no encontrado");
        }
      } else {
        toast.error("No hay servicios para eliminar");
      }
    } else {
      toast.error("Usuario no encontrado");
    }
  } catch (err) {
    toast.error(
      "Hubo un problema al eliminar el servicio. Por favor inténtelo nuevamente."
    );
    console.error(err);
  }
};

const getPaymentsByUserId = async (userId) => {
  const userDocRef = doc(db, "patients", userId);
  try {
    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      return data.services;
    } else {
      toast.error("Usuario no encontrado");
    }
  } catch (err) {
    toast.error(
      "Hubo un problema al agregar el pago. Por favor inténtelo nuevamente."
    );
    console.log(err);
  }
};

const getActivePaymentsByUserId = async (userId) => {
  const userDocRef = doc(db, "patients", userId);
  try {
    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      let activeData = data?.services?.filter((e) => !e.hasBeenPaid);
      return activeData;
    } else {
      toast.error("Usuario no encontrado");
    }
  } catch (err) {
    toast.error(
      "Hubo un problema al agregar el pago. Por favor inténtelo nuevamente."
    );
    console.log(err);
  }
};

const getAllPaymentsByPraxisId = async (praxisId) => {
  try {
    const data = [];
    const q = query(
      collection(db, "payments"),
      where("praxisId", "==", praxisId)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
    debugger;
    return data;
  } catch (err) {
    toast.error(
      "Hubo un problema al agregar el pago. Por favor inténtelo nuevamente."
    );
    console.log(err);
  }
};

const allPaymentsByUIdAndPId = async (praxisId, patientId) => {
  try {
    const data = [];
    const q = query(
      collection(db, "payments"),
      where("praxisId", "==", praxisId),
      where("patientId", "==", patientId)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
    return data;
  } catch (err) {
    toast.error(
      "Hubo un problema al agregar el pago. Por favor inténtelo nuevamente."
    );
    console.log(err);
  }
};

const addPayment = async (
  serviceId,
  service,
  newPayment,
  modalSetter,
  allPaymentsSetter
) => {
  const paymentDocRef = doc(db, "payments", serviceId);
  try {
    const paymentDocSnapshot = await getDoc(paymentDocRef);

    if (paymentDocSnapshot.exists()) {
      // const paymentData = paymentDocSnapshot.data();
      debugger;
      service.payments.push(newPayment);
      service.balance = service.balance - newPayment.amount;
      if (service.balance === 0) {
        service.hasBeenPaid = true;
      }
      debugger;
      console.log(service);
      // await setDoc(doc(db, "payments", serviceId), service);
      await updateDoc(paymentDocRef, service);
      // allPaymentsSetter(data.services);
      toast.success("Pago agregado exitosamente");
      modalSetter(false);
      allPaymentsSetter(service);
      return service;
    }
  } catch (err) {
    toast.error(
      "Hubo un problema al agregar el pago. Por favor inténtelo nuevamente."
    );
    console.log(err);
  }
};

const updatePayment = async (
  serviceId,
  type,
  paymentId,
  updatedPayment,
  //
  service,
  newPayment,
  modalSetter,
  allPaymentsSetter
) => {
  const paymentDocRef = doc(db, "payments", serviceId);
  try {
    const paymentDocSnapshot = await getDoc(paymentDocRef);

    if (paymentDocSnapshot.exists()) {
      // const paymentData = paymentDocSnapshot.data();
      debugger;
      if (type === "service") {
        service.payments.push(newPayment);
        service.balance = service.balance - newPayment.amount;
        if (service.balance === 0) {
          service.hasBeenPaid = true;
        }
        debugger;
        console.log(service);
        // await setDoc(doc(db, "payments", serviceId), service);
        await updateDoc(paymentDocRef, service);
        toast.success("Pago agregado exitosamente");
      } else if (type === "payment") {
        const paymentData = paymentDocSnapshot.data();
        debugger;
        const selectedPayment = paymentData?.payments.find(
          (e) => e.id == paymentId
        );
        debugger;
        selectedPayment = updatedPayment;
        // await updateDoc(paymentDocRef, service);
      }
      // allPaymentsSetter(data.services);
      // toast.success("Pago agregado exitosamente");
      modalSetter(false);
      allPaymentsSetter(service);
      return service;
    }
  } catch (err) {
    toast.error(
      "Hubo un problema al agregar el pago. Por favor inténtelo nuevamente."
    );
    console.log(err);
  }
};

export {
  updateOrAddPayment,
  deletePaymentItem,
  getPaymentsByUserId,
  getActivePaymentsByUserId,
  getAllPaymentsByPraxisId,
  allPaymentsByUIdAndPId,
  addPayment,
  updatePayment,
};
