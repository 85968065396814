import React, { useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import PaymentsTable from "../clientViews/allViews/payments/PaymentsTable";
import { useSelector } from "react-redux";
import FinancesTable from "./FinancesTable";
import FinanceControllers from "./FinanceControllers";
import { getAllPaymentsByPraxisId } from "../../firebase/firebase.payment";
import PaymentModal from "../UI/PaymentModal";

const Finances = () => {
  const [allPayments, setAllPayments] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("all");
  //   const [data, setData] = useState(null);
  const [praxisId, setPraxisId] = useState(null);
  const localPraxis = useSelector((state) => state.user?.userDetails);
  const allPatients = useSelector((state) => state.patients.allPatients);

  //   useEffect(() => {
  //     let getNewData = async () => {
  //       if (praxisId) {
  //         let data = []; //await getData(praxisId);
  //         if (data !== undefined) {
  //           setAllPayments(data);
  //         }
  //       }
  //     };
  //     getNewData();
  //   }, [praxisId]);

  // useEffect(() => {
  //   // debugger;
  //   console.log(allPatients);
  //   if (allPatients) {
  //     let allPatientsCopy = JSON.parse(JSON.stringify(allPatients));
  //     // debugger;
  //     const filteredPayments = [];
  //     allPatientsCopy.map((e) => {
  //       if (e.services) {
  //         e.services.map((s) => {
  //           s.patientDetails = {
  //             patientName: e.personalDetails.name,
  //             patientId: e.id,
  //           };
  //           filteredPayments.push(s);
  //         });
  //       }
  //     });
  //     console.log(filteredPayments);
  //     // debugger;
  //     setAllPayments(filteredPayments);
  //   }
  // }, [allPatients]);

  useEffect(() => {
    if (praxisId) {
      const allPayments = async () => {
        debugger;
        const payments = await getAllPaymentsByPraxisId(praxisId);
        console.log(payments);
        setAllPayments(payments);
      };
      allPayments();
    }
  }, [praxisId]);

  useEffect(() => {
    if (localPraxis && praxisId == null) setPraxisId(localPraxis.praxisId);
  }, [localPraxis]);

  return (
    <div>
      <Navbar>
        <main
          className="flex flex-wrap bg-slate-800"
          style={{ justifyContent: "center" }}
        >
          <div className="mx-auto w-full">
            <div className="">
              <div className="">
                {allPayments && selectedCategory === "all" && (
                  <FinancesTable
                    userData={allPayments}
                    setOpenModal={setOpenModal}
                    setSelectedCategory={setSelectedCategory}
                    selectedCategory={selectedCategory}
                  />
                )}
                {/* {allPayments && selectedCategory === "pending" && (
                          <FinancesTable
                            userData={allPayments}
                            setOpenModal={setOpenModal}
                          />
                        )}
                        {allPayments && selectedCategory === "paid" && (
                          <FinancesTable
                            userData={allPayments}
                            setOpenModal={setOpenModal}
                          />
                        )} */}
              </div>
            </div>
          </div>
          {openModal && (
            <PaymentModal
              data={allPayments}
              modalState={openModal}
              modalSetter={setOpenModal}
              allPaymentsSetter={setAllPayments}
              allPayments={allPayments}
            />
          )}
        </main>
      </Navbar>
    </div>
  );
};

export default Finances;
