import React, { useEffect, useState } from "react";
import Spinner from "../../../UI/Spinner";
import PaymentsRow from "./PaymentsRow";

function PaymentsTable({ userData, setOpenModal }) {
  const [allpayments, setAllpayments] = useState(null);
  const [mode, setMode] = useState("lightmode");

  useEffect(() => {
    setAllpayments(userData);
  }, [userData]);

  return (
    <>
      <div className={`w-full max-h-fit `}>
        <div className="relative overflow-x-auto h-[50vh]">
          {allpayments ? (
            allpayments.length > 0 ? (
              <table className="w-full text-sm text-center rtl:text-right text-gray-500 dark:text-gray-400 ">
                {/* {userData && ( */}
                <thead
                  className="text-xs text-gray-600"
                  style={{ backgroundColor: "#4A4A4A08" }}
                >
                  <tr>
                    <th scope="col" className="px-6 py-3" id="1">
                      Servicio
                    </th>
                    <th scope="col" className="px-6 py-3" id="2">
                      Descripcion
                    </th>
                    <th scope="col" className="px-6 py-3" id="3">
                      Fecha
                    </th>
                    <th scope="col" className="px-6 py-3" id="4">
                      Precio
                    </th>
                    <th scope="col" className="px-6 py-3" id="5">
                      Estado
                    </th>
                    <th scope="col" className="px-6 py-3" id="6">
                      Balance
                    </th>
                  </tr>
                </thead>
                {/* // )} */}
                <tbody>
                  {
                    userData.map((m, i) => (
                      <PaymentsRow
                        reason={m.reason}
                        description={m.description}
                        hasBeenPaid={m.hasBeenPaid}
                        date={m.date}
                        balance={m.balance}
                        price={m.price}
                        id={m.id}
                        key={i}
                      />
                    ))
                    // ) : (
                    //   <div
                    //     className="flex items-center justify-center"
                    //     style={{ height: "70vh", width: "100vw" }}
                    //   >
                    //     <Spinner />
                    //   </div>
                    // )
                  }
                </tbody>
              </table>
            ) : (
              <div className="flex justify-center w-full">
                <div className="w-3/4 text-center bg-white border border-gray-200 rounded-lg shadow p-3">
                  <div>Agrega un nuevo pago del pacienteaquí</div>
                </div>
              </div>
            )
          ) : (
            <div
              className="flex items-center justify-center"
              style={{ height: "20vh", width: "40vw" }}
            >
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PaymentsTable;
