import React, { useEffect, useState } from "react";
import Spinner from "../UI/Spinner";
import FinancesRow from "./FinancesRow";
// import AddPatientModal from "../addPatient/AddPatientModal";
import Filter from "../filter/Filter";
import { useDispatch, useSelector } from "react-redux";
import FinanceControllers from "./FinanceControllers";
// import { setFilteredPatients } from "../../../store/patients/patients.reducer";

function FinancesTable({ userData, setSelectedCategory, selectedCategory }) {
  const [mode, setMode] = useState("darkmode");
  const [filteredData, setFilteredData] = useState(userData);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const filteredPatients = useSelector(
    (state) => state.patients.filteredPatients
  );

  const filterPatients = (e) => {
    if (e) {
      setFilteredData(e);
      // dispatch(setFilteredPatients(e));
    }
  };

  useEffect(() => {
    if (userData) {
      setFilteredData(userData);
      // dispatch(setFilteredPatients(userData));
      console.log(filteredPatients);
    }
  }, [userData]);

  useEffect(() => {
    // if (userData) {
    // setFilteredData(userData);
    // dispatch(setFilteredPatients(userData));
    console.log(mode);
    // }
  }, [mode]);

  return (
    <>
      <div
        className={`w-full h-[91vh] rounded-lg shadow ${
          mode !== "darkmode" ? "bg-white" : "bg-slate-600"
        } `}
      >
        <div className="flex justify-between ml-1">
          <div className="flex items-center">
            {/* <Filter
              userData={userData}
              filterData={filterPatients}
              originalPatients={userData}
            /> */}
            <div className="flex justify-start m-2">
              <div
                className="m-1 p-1 flex text-white rounded-md"
                style={{ backgroundColor: "#253141" }}
                onClick={() => setOpenModal(true)}
              >
                <div className="px-1 font-bold">+</div>
                <div className="px-1">Agregar Acción</div>
              </div>
            </div>

            <FinanceControllers
              setSelectedCategory={setSelectedCategory}
              currentCategory={selectedCategory}
            />
          </div>

          <div className="flex items-center">
            <div
              className={`m-1 p-1 flex ${
                mode === "darkmode" ? "bg-yellow-400" : "bg-cyan-950"
              } text-white rounded-md`}
            >
              {mode === "darkmode" ? (
                <div className="flex" onClick={() => setMode("lightmode")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                    />
                  </svg>
                </div>
              ) : (
                <div className="flex" onClick={() => setMode("darkmode")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="overflow-x-auto no-scrollbar shadow-md sm:rounded-lg ml-2 h-[80vh]">
          {/* <table className="relative w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"> */}
          {/* {userData && (
              <thead className="text-xs text-gray-700 sticky top-0 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 rounded-tl-lg"
                    colSpan={1}
                  >
                    Servicio
                  </th>
                  <th scope="col" className="px-6 py-3" colSpan={1}>
                    Descripción
                  </th>
                  <th scope="col" className="px-6 py-3" colSpan={1}>
                    Fecha
                  </th>
                  <th scope="col" className="px-6 py-3" colSpan={1}>
                    Precio
                  </th>

                  <th scope="col" className="px-6 py-3" colSpan={1}>
                    Estado
                  </th>

                  <th scope="col" className="px-6 py-3" colSpan={1}>
                    Balance
                  </th>

                  <th scope="col" className="px-6 py-3" colSpan={1}>
                    Paciente
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 rounded-tr-lg"
                    colSpan={1}
                  ></th>
                </tr>
              </thead>
            )} */}
          {/* <tbody> */}
          {/* {filteredData ? (
              <tbody className="">
                {filteredData.map((m, i) => (
                  <FinancesRow
                    reason={m.reason}
                    description={m.description}
                    hasBeenPaid={m.hasBeenPaid}
                    date={m.date}
                    balance={m.balance}
                    price={m.price}
                    id={m.id}
                    key={i}
                    patient={m.patientName}
                    mode={mode}
                  />
                ))}
              </tbody>
            ) : (
              <div
                className="flex items-center justify-center"
                style={{ height: "70vh", width: "100vw" }}
              >
                <Spinner />
              </div>
            )} */}
          {/* </tbody> */}
          {/* </table> */}

          <div class="">
            <div class="min-w-full relative w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <div class="bg-gray-200 text-gray-700 text-xs text-gray-700 sticky top-0 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <div class="grid grid-cols-12 gap-0">
                  <div class="p-4 col-span-2 flex justify-left border-b border-gray-300">
                    Servicio
                  </div>
                  <div class="py-4 col-span-4 flex justify-center border-b border-gray-300">
                    Descripción
                  </div>
                  <div class="py-4 col-span-1 flex justify-left border-b border-gray-300">
                    Fecha
                  </div>
                  <div class="py-4 col-span-1 flex justify-center border-b border-gray-300">
                    Precio
                  </div>
                  <div class="py-4 col-span-1.5 flex justify-center border-b border-gray-300">
                    Estado
                  </div>
                  <div class="py-4 col-span-1 flex justify-center  border-b border-gray-300">
                    Balance
                  </div>
                  <div class="py-4 col-span-1 flex justify-left border-b border-gray-300">
                    Paciente
                  </div>
                  <div class="py-4 col-auto flex justify-left border-b border-gray-300"></div>
                </div>
              </div>
              {/* 
              <div class="grid grid-cols-7 gap-0">
                <div class="p-4 border-b border-gray-300">Data 1A</div>
                <div class="p-4 border-b border-gray-300">Data 1B</div>
                <div class="p-4 border-b border-gray-300">Data 1C</div>
                <div class="p-4 border-b border-gray-300">Data 1C</div>
                <div class="p-4 border-b border-gray-300">Data 1C</div>
                <div class="p-4 border-b border-gray-300">Data 1C</div>
                <div class="p-4 border-b border-gray-300">Data 1C</div>
              </div> */}

              {filteredData ? (
                <tbody className="">
                  {filteredData.map((m, i) => (
                    <FinancesRow
                      reason={m.reason}
                      description={m.description}
                      hasBeenPaid={m.hasBeenPaid}
                      date={m.date}
                      balance={m.balance}
                      price={m.price}
                      id={m.id}
                      key={i}
                      patient={m.patientName}
                      mode={mode}
                      payments={m.payments}
                    />
                  ))}
                </tbody>
              ) : (
                <div
                  className="flex items-center justify-center"
                  style={{ height: "70vh", width: "100vw" }}
                >
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* {openModal && (
        <AddPatientModal modalState={openModal} modalSetter={setOpenModal} />
      )} */}
    </>
  );
}

export default FinancesTable;
