import React, { useEffect, useState } from "react";
import Spinner from "../../UI/Spinner";
import PatientRow from "./PatientRow";
import AddPatientModal from "../addPatient/AddPatientModal";
import Filter from "../../filter/Filter";
import { useDispatch, useSelector } from "react-redux";
import { setFilteredPatients } from "../../../store/patients/patients.reducer";

function PatientsTable({ userData }) {
  const [mode, setMode] = useState("darkmode");
  const [filteredData, setFilteredData] = useState(userData);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const filteredPatients = useSelector(
    (state) => state.patients.filteredPatients
  );

  const filterPatients = (e) => {
    if (e) {
      setFilteredData(e);
      dispatch(setFilteredPatients(e));
    }
  };

  useEffect(() => {
    if (userData) {
      setFilteredData(userData);
      // dispatch(setFilteredPatients(userData));
      console.log(filteredPatients);
    }
  }, [userData]);

  useEffect(() => {
    // if (userData) {
    // setFilteredData(userData);
    // dispatch(setFilteredPatients(userData));
    console.log(mode);
    // }
  }, [mode]);

  return (
    <>
      <div
        className={`w-full h-[91vh] rounded-lg shadow ${
          mode !== "darkmode" ? "bg-white" : "bg-slate-600"
        } `}
      >
        <div className="flex justify-between ml-1">
          <div className="flex items-center">
            <div className="">
              <div
                className="m-1 p-1 flex items-center text-white rounded-md"
                style={{ backgroundColor: "#253141" }}
                onClick={() => setOpenModal(true)}
              >
                <div className="px-1 font-bold">+</div>
                <div className="px-1">Nuevo Paciente</div>
              </div>
            </div>

            <Filter
              userData={userData}
              filterData={filterPatients}
              originalPatients={userData}
            />
          </div>

          <div className="flex items-center">
            <div
              className={`m-1 p-1 flex ${
                mode === "darkmode" ? "bg-yellow-400" : "bg-cyan-950"
              } text-white rounded-md`}
            >
              {mode === "darkmode" ? (
                <div className="flex" onClick={() => setMode("lightmode")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                    />
                  </svg>
                </div>
              ) : (
                <div className="flex" onClick={() => setMode("darkmode")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="overflow-x-auto no-scrollbar shadow-md sm:rounded-lg ml-2 h-[80vh]">
          <table className="relative w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            {userData && (
              <thead className="text-xs text-gray-700 sticky top-0 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Nombre
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Nacimiento
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Genero
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Ultima Visita
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Telefono
                  </th>
                </tr>
              </thead>
            )}
            {/* <tbody> */}
            {filteredData ? (
              <tbody className="">
                {filteredData.map((m, i) => (
                  <PatientRow
                    name={m.personalDetails.name}
                    tel={m.personalDetails.tel}
                    birthdate={m.personalDetails.birthdate}
                    gender={m.personalDetails.gender}
                    visit={m.odontologicPast.lastVisit}
                    id={m.id}
                    mode={mode}
                    url={m.id}
                    payments={m.payments}
                    key={i}
                  />
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td
                    className="flex items-center justify-center"
                    style={{ height: "70vh", width: "100vw" }}
                  >
                    <Spinner />
                  </td>
                </tr>
              </tbody>
            )}
            {/* </tbody> */}
          </table>
        </div>
      </div>

      {openModal && (
        <AddPatientModal modalState={openModal} modalSetter={setOpenModal} />
      )}
    </>
  );
}

export default PatientsTable;
