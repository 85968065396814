import { Disclosure } from "@headlessui/react";
import { logout } from "../../firebase/firebase.user";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeState } from "../../store/user/user.reducer";
import FeedbackModal from "../feedback/FeedbackModal";

export default function Navbar({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.userDetails);

  const logoutHandler = () => {
    logout();
    dispatch(removeState());
    navigate("/");
  };

  return (
    <Disclosure as="nav" style={{ backgroundColor: "#253141" }}>
      {({ open }) => (
        <div className="overflow-x-auto no-scrollbar h-100vh">
          <div className="px-2">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex items-center justify-center sm:items-stretch sm:justify-start sm:w-1/3">
                <div className="flex flex-shrink-0 items-center">
                  <h6 className="mb-0 font-bold text-white capitalize text-2xl ml-[4vw]">
                    OdontoStark
                  </h6>
                </div>
              </div>

              <div className="flex">
                {user && user?.role !== "admin" && <FeedbackModal />}

                <div className="flex">
                  <button
                    className="inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={logoutHandler}
                  >
                    LogOut
                  </button>
                </div>
              </div>
            </div>
          </div>
          {user && user?.role === "admin" ? (
            <header
              className="bg-slate-800 shadow pl-1"
              style={{ backgroundColor: "#253141" }}
            >
              <div className="flex lg:justify-start lg:flex-row flex-col">
                <div className="lg:h-[91vh] max-w-7xl rounded-t-lg">
                  <div className="lg:mt-10 flex lg:flex-col flex-row w-full">
                    <Link className="py-4 px-2" to={"/errors"}>
                      <div className="flex flex-col justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="white"
                          className="w-6 h-6 lg:flex hidden"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                          />
                        </svg>

                        <h1 className="lg:text-sm font-bold text-white tracking-tight text-gray-900">
                          Errores
                        </h1>
                      </div>
                    </Link>
                    <Link className="py-4 px-2" to={"/admin"}>
                      <div className="flex flex-col justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="white"
                          className="w-6 h-6 lg:flex hidden"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                          />
                        </svg>

                        <h1 className="lg:text-sm font-bold text-white tracking-tight text-gray-900">
                          Admin
                        </h1>
                      </div>
                    </Link>
                    <Link className="py-4 px-2" to={"/register"}>
                      <div className="flex flex-col justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="white"
                          className="w-6 h-6 lg:flex hidden"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                          />
                        </svg>

                        <h1 className="lg:text-sm font-bold text-white tracking-tight text-gray-900">
                          Registrar
                        </h1>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="w-full overflow-x-auto no-scrollbar">
                  {children}
                </div>
              </div>
            </header>
          ) : (
            <header
              className="bg-slate-800 shadow"
              style={{ backgroundColor: "#253141" }}
            >
              <div className="flex lg:justify-start lg:flex-row flex-col">
                <div className="lg:h-[91vh] max-w-7xl rounded-t-lg w-[4vw]">
                  <div className="flex lg:flex-col flex-row w-full">
                    <Link className="py-4 px-2" to={"/calendario"}>
                      <div className="flex flex-col justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="white"
                          className="w-8 h-8 lg:flex hidden"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                          />
                        </svg>

                        <div className="text-xs lg:hidden font-bold text-white tracking-tight text-gray-900">
                          Citas
                        </div>
                      </div>
                    </Link>
                    <Link className="py-4 px-2" to={"/dashboard"}>
                      <div className="flex flex-col justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="white"
                          className="w-6 h-6 lg:flex hidden"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                          />
                        </svg>

                        <div className="text-xs lg:hidden font-bold text-white tracking-tight text-gray-900">
                          Pacientes
                        </div>
                      </div>
                    </Link>
                    <Link className="py-4 px-2" to={"/finanzas"}>
                      <div className="flex flex-col justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="white"
                          className="w-6 h-6 lg:flex hidden"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                          />
                        </svg>

                        <div className="text-xs lg:hidden font-bold text-white tracking-tight text-gray-900">
                          Finanzas
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="w-full overflow-x-auto no-scrollbar">
                  {children}
                </div>
              </div>
            </header>
          )}

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3"></div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
}
