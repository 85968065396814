import { useEffect, useRef, useState } from "react";
import {
  addPayment,
  updateOrAddPayment,
} from "../../firebase/firebase.payment";
import PaymentDropdown from "./PaymentDropdown";
import { toast } from "react-hot-toast";
import DatePicker from "react-datepicker";
import { DatePickerIcon } from "./DatePickerIcon";
import Spinner from "./Spinner";
import { useSelector } from "react-redux";
import { generateUuid } from "../../firebase/firebase.helpers";

const PaymentModal = ({
  data,
  modalState,
  modalSetter,
  allPayments,
  allPaymentsSetter,
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewService, setIsNewService] = useState(false);
  const [dropSelection, setDropSelection] = useState(null);
  const [defaultValue, setDefaultValue] = useState(undefined);
  const [currentDebt, setCurrentDebt] = useState(0);
  const [invoice, setInvoice] = useState("");
  const [paymentReason, setPaymentReason] = useState(undefined);
  const [paymentDescription, setPaymentDescription] = useState(undefined);
  const [paymentAmount, setPaymentAmount] = useState(undefined);
  const [serviceIsRunning, setServiceIsRunning] = useState(false);
  const [paymentDate, setPaymentDate] = useState(new Date());
  const cancelButtonRef = useRef(undefined);
  const user = useSelector((state) => state.user?.userDetails);

  useEffect(() => {
    if (allPayments) {
      if (data.services) {
        let serviceToCharge = data.services.find((i) => i.hasBeenPaid !== true);
        setDropSelection(serviceToCharge);
        if (serviceToCharge !== undefined) {
          setDefaultValue(serviceToCharge);
        } else {
          setDefaultValue([]);
        }
      }
    }
  }, [allPayments]);

  useEffect(() => {
    console.log(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (modalState !== open) {
      setOpen(modalState);
    }
  }, [open]);

  useEffect(() => {
    if (dropSelection) {
      // // debugger
      if (paymentAmount !== undefined && paymentAmount !== "") {
        // // debugger
        setCurrentDebt(dropSelection.balance - paymentAmount);
      } else {
        // // debugger
        setCurrentDebt(dropSelection.balance);
      }
    }
  }, [dropSelection, paymentAmount]);

  const handleChange = (e) => {
    // Extract the input value
    let value = e.target.value;
    // Remove non-numeric characters
    value = value.replace(/[^0-9]/g, "");
    // Ensure the value is a number and greater than 0
    if (!isNaN(value) && parseInt(value) > 0) {
      if (isNewService && value) {
        setPaymentAmount(value);
      }

      if (!isNewService) {
        if (value <= dropSelection.balance) {
          setPaymentAmount(value);
        } else {
          toast.error("El monto supera el balance a pagar.");
        }
      }
    } else {
      toast.error("El valor no es un numero o es menor que 0");
      setPaymentAmount(value);
    }
  };

  const updatePaymentHandler = () => {
    // debugger;
    setServiceIsRunning(true);
    let paymentAmountHandler =
      typeof paymentAmount === "string"
        ? JSON.parse(paymentAmount)
        : paymentAmount;
    if (!isNewService) {
      debugger;
      addPayment(
        dropSelection.id,
        dropSelection,
        {
          amount: paymentAmountHandler,
          invoice: invoice,
          date: paymentDate,
          id: generateUuid(),
          description: paymentDescription,
        },
        modalSetter,
        allPaymentsSetter,
        setServiceIsRunning
      );
    } else {
      debugger;
      updateOrAddPayment(
        data.id,
        { id: 0 },
        {
          balance: paymentAmountHandler,
          date: new Date(paymentDate).toISOString(),
          hasBeenPaid: false,
          price: paymentAmountHandler,
          payments: [],
          id: "",
          reason: paymentReason,
          description: paymentDescription,
          praxisId: user.praxisId,
          praxisName: data.praxisName,
          patientId: data.id,
          patientName: data.personalDetails.name,
          createdBy: user.name,
          createdById: user.id,
        },
        modalSetter,
        allPaymentsSetter,
        setServiceIsRunning
      );
    }
  };

  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
        style={{ zIndex: "10000" }}
      >
        <div className="relative my-6 mx-auto lg:w-50vw md:w-70vw sm:w-80vw w-70vw">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-12">
            {/*header*/}
            {
              <>
                <div className="flex items-center w-full justify-left p-1 m-1 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-xl font-semibold">Pagos y Servicios</h3>
                </div>
                <div className="w-full">
                  <label className="text-xs">
                    <span className="text-red-500 pr-1">*</span>Seleccione el
                    tipo de servicio
                  </label>
                  <div className="mt-2">
                    <div className="mt-2 overflow-y-auto">
                      <div className="">
                        <div className="flex justify-around">
                          <div
                            className="py-4 px-6 bg-white text-sm border border-gray-200 rounded-lg shadow w-2/12"
                            onClick={(e) => setIsNewService(true)}
                          >
                            <div className="flex flex-col justify-center items-center">
                              <div>
                                <svg
                                  viewBox="0 0 64 64"
                                  fill="currentColor"
                                  className="w-12 h-8 mb-3"
                                >
                                  <path
                                    fill="none"
                                    stroke="currentColor"
                                    strokeMiterlimit={10}
                                    strokeWidth={2}
                                    d="M32 27v18M41 36H23M23 8H10v55h44V8H41"
                                  />
                                  <path
                                    fill="none"
                                    stroke="currentColor"
                                    strokeMiterlimit={10}
                                    strokeWidth={2}
                                    d="M36 5V1h-8v4h-4l-2 8h20l-2-8z"
                                  />
                                </svg>
                              </div>
                              <div className="text-xs">Nuevo servicio</div>
                            </div>
                          </div>

                          <div
                            className="px-3 py-3 bg-white text-sm border border-gray-200 rounded-lg shadow w-2/12"
                            onClick={(e) => setIsNewService(false)}
                          >
                            <div className="flex flex-col justify-center items-center">
                              <div>
                                <svg
                                  viewBox="0 0 64 64"
                                  fill="currentColor"
                                  className="w-12 h-8 mb-3"
                                >
                                  <path
                                    fill="none"
                                    stroke="currentColor"
                                    strokeMiterlimit={10}
                                    strokeWidth={2}
                                    d="M23 8H10v55h44V8H41"
                                  />
                                  <path
                                    fill="none"
                                    stroke="currentColor"
                                    strokeMiterlimit={10}
                                    strokeWidth={2}
                                    d="M36 5V1h-8v4h-4l-2 8h20l-2-8z"
                                  />
                                  <path
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinejoin="round"
                                    strokeMiterlimit={10}
                                    strokeWidth={2}
                                    d="M32 41l-6.125 4L28 38l-6-4h7.213L32 26l3 8h7l-6 4 1.938 7z"
                                  />
                                </svg>
                              </div>
                              <div className="text-xs">Servicios activos</div>
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col mt-5">
                          {isNewService && (
                            <div className="flex flex-col mt-5">
                              <div className="m-2">
                                {/* <label className="w-3/12 leading-tight dark:text-black/80">
                                  Servicio
                                </label> */}
                                <label className="text-xs leading-tight dark:text-black/80 m-1 flex flex-col w-11/12">
                                  Servicio:
                                </label>
                                <input
                                  type="text"
                                  id="service"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                  required
                                  value={paymentReason}
                                  onChange={(e) =>
                                    setPaymentReason(e.target.value)
                                  }
                                />
                              </div>
                              <div className="m-2">
                                <label className="w-3/12 text-xs leading-tight dark:text-black/80 m-1 flex flex-col">
                                  Costo
                                </label>
                                <input
                                  type="text"
                                  id="amount"
                                  className="bg-gray-50 font-semibold border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                  value={
                                    paymentAmount
                                      ? paymentAmount
                                          .toString()
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      : ""
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="m-2">
                                <label className="w-3/12 text-xs leading-tight dark:text-black/80 m-1 flex flex-col">
                                  Descripción:
                                </label>
                                <textarea
                                  type="text"
                                  id="service"
                                  className="bg-gray-50 border whitespace-pre-wrap border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                  required
                                  value={paymentDescription}
                                  onChange={(e) =>
                                    setPaymentDescription(e.target.value)
                                  }
                                />
                              </div>

                              <div className="flex flex-col m-2">
                                <label className="w-3/12 mb-2 text-xs leading-tight dark:text-black/80 m-1 flex flex-col">
                                  Día de Pago
                                </label>
                                <DatePicker
                                  selected={paymentDate}
                                  onChange={(date) => setPaymentDate(date)}
                                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pl-10 p-2"
                                  popperPlacement="top"
                                  showIcon
                                  icon={DatePickerIcon}
                                />
                              </div>
                            </div>
                          )}

                          {!isNewService &&
                            defaultValue &&
                            defaultValue.length == 0 && (
                              <div className="mt-4 italic text-gray-500">
                                <div>El usuario no tiene servicios activos</div>
                              </div>
                            )}

                          {!isNewService && data && dropSelection && (
                            <div className="flex flex-col mt-5">
                              <div className="flex justify-around mb-2">
                                <div className="w-9/12">
                                  <label className="mb-2 text-xs leading-tight dark:text-black/80 m-1 flex flex-col w-11/12">
                                    Servicio:
                                  </label>
                                  <PaymentDropdown
                                    data={allPayments}
                                    currentValueSetter={setDropSelection}
                                    defaultValue={defaultValue}
                                  />
                                </div>
                                {dropSelection !== null && (
                                  <div className="flex flex-col">
                                    <label className="mb-2 text-xs leading-tight dark:text-black/80 m-2 flex flex-col">
                                      Saldo Pendiente:
                                    </label>
                                    <div
                                      className="
                                 
                                    text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2 ml-2"
                                    >
                                      {currentDebt && currentDebt}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="flex">
                                <div className="m-2 w-6/12 mb-2 text-xs leading-tight dark:text-black/80 m-1 flex flex-col">
                                  <label className="">Pago de:</label>
                                  <input
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                    required
                                    value={paymentAmount}
                                    onChange={handleChange}
                                    type="number"
                                  />
                                </div>
                                <div className="m-2 w-6/12 mb-2 text-xs leading-tight dark:text-black/80 m-1 flex flex-col">
                                  <label>Num de Factura:</label>
                                  <input
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                    required
                                    value={invoice}
                                    onChange={(e) => setInvoice(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="m-2">
                                <label className="w-3/12 text-xs leading-tight dark:text-black/80 flex flex-col">
                                  Descripción
                                </label>
                                <textarea
                                  type="text"
                                  id="service"
                                  className="bg-gray-50 whitespace-pre-wrap border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                  required
                                  value={paymentDescription}
                                  onChange={(e) =>
                                    setPaymentDescription(e.target.value)
                                  }
                                />
                              </div>
                              <div className="m-2 flex flex-col mb-2 text-xs leading-tight dark:text-black/80 m-1 flex flex-col">
                                <label>Día del Pago</label>
                                <DatePicker
                                  selected={paymentDate}
                                  onChange={(date) => setPaymentDate(date)}
                                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pl-10 p-2"
                                  popperPlacement="top"
                                  showIcon
                                  icon={DatePickerIcon}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => modalSetter(false)}
                    ref={cancelButtonRef}
                  >
                    Cerrar
                  </button>
                  <div className="flex bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                    <button
                      className=""
                      type="button"
                      onClick={updatePaymentHandler}
                      disabled={serviceIsRunning}
                    >
                      Guardar
                    </button>
                    {isLoading && (
                      <div className="ml-2">
                        <Spinner size="small" fill="fill-emerald-800" />
                      </div>
                    )}
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default PaymentModal;
