import React from "react";
import { useNavigate } from "react-router-dom";
import Pill from "../../../UI/Pill";

const PaymentRow = ({
  reason,
  date,
  description,
  hasBeenPaid,
  mode,
  url,
  balance,
  price,
}) => {
  const history = useNavigate();
  //   const onUserHandler = (e) => {
  //     history(`/${url}`);
  //   };
  return (
    <tr
      //   onClick={onUserHandler}
      className={`${
        mode === "darkmode"
          ? `odd:dark:bg-gray-900 even:dark:bg-gray-800 dark:border-gray-700`
          : "odd:bg-white even:bg-gray-50 border-b text-gray-700"
      }`}
    >
      <th
        scope="row"
        className={`${
          mode === "darkmode" ? "dark:text-white" : "text-sky-500"
        } ' px-6 py-4 font-medium whitespace-nowrap w-2/12`}
      >
        {reason}
      </th>
      <th
        scope="row"
        className={`${
          mode === "darkmode" ? "dark:text-white" : "text-sky-500"
        } ' px-6 py-4 font-medium whitespace-pre-wrap text-left w-5/12`}
      >
        {description}
      </th>
      {/* <td className="px-6 py-4">{description}</td> */}
      <td className="px-6 py-4 whitespace-nowrap">
        {date && date?.seconds !== undefined
          ? new Date(date.seconds * 1000).toISOString().split("T")[0]
          : date.split("T")[0]}
      </td>
      <td className="px-6 py-4 whitespace-nowrap ">
        {price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}
      </td>
      <td className="px-6 py-4 whitespace-nowrap ">
        {hasBeenPaid ? (
          <Pill text="Pagado" color="green" />
        ) : (
          <Pill text="Pendiente" color="red" />
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap ">
        {balance
          ? balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : ""}
      </td>
    </tr>
  );
};

export default PaymentRow;
