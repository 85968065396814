import React, { useEffect, useState } from "react";
import "./Odontogram.css";
import Teeth from "./Teeth";
import { addOdontograma } from "../../../../../firebase/firebase.odontograma";
import { useSelector } from "react-redux";
import { initiateCluster } from "../../../../../firebase/firebase";
import Spinner from "../../../../UI/Spinner";

function Odontogram({ data }) {
  const [odontogramData, setOdontogramData] = useState(null);
  const [actions, setActions] = useState(null);
  const [subActions, setSubActions] = useState(null);
  const [toothNumber, setToothNumber] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const userDB = useSelector((state) => state.user.details);

  let odontogramState = {};

  const handleToothUpdate = (id, toothState) => {
    odontogramState[id] = toothState;
  };

  useEffect(() => {
    if (data) {
      setOdontogramData(data.odontograma);
    }
  }, [data]);

  const actionHandler = (e) => {
    setSubActions(null);
    setActions(e);
  };

  const handleClick = (func) => {
    setSubActions(func);
  };

  const toothNumberHandler = (e) => {
    setToothNumber(e);
  };

  const saveOdontogram = async () => {
    setIsLoading(true);
    await addOdontograma(data.id, odontogramState);
    setIsLoading(false);
    setActions(null);
    setSubActions(null);
    setToothNumber(null);
  };

  return (
    <>
      {/* <main
        className="flex flex-wrap bg-slate-800"
        style={{ justifyContent: "center" }}
      >
        <div className="mx-auto max-w-7xl py-6 sm:px-6 md:px-6  mx-2">
          <div className="w-full mt-3 bg-white rounded-lg ">
            <div className="flex"> */}
      <div className="flex flex-col justify-center rounded-lg p-5 sm:h-70vh md:h-70vh lg:h-[53vh]">
        <div className="m-5">
          <div>
            <div>
              <div className="flex justify-between items-center">
                <div className="flex w-80vw">
                  <div className="flex py-2 px-4 overflow-scroll">
                    {toothNumber && (
                      <div className="flex items-center">
                        <h2 className="bg-gray-800 hover:bg-gray-400 text-white font-semibold mx-0.5 py-1 px-2 rounded cursor-pointer transition duration-300 inline-flex items-left">
                          {toothNumber && toothNumber}
                        </h2>
                      </div>
                    )}
                    {actions &&
                      Object.entries(actions).map(([key, value]) => (
                        <div
                          key={key}
                          onClick={() => handleClick(value)}
                          className="bg-slate-600 hover:bg-slate-800 text-white font-semibold mx-0.5 py-1 px-2 rounded cursor-pointer transition duration-300 inline-flex items-left"
                        >
                          {key}
                        </div>
                      ))}
                  </div>
                  {subActions !== null && (
                    <div className="flex items-center">
                      <div className="flex max-w-full px-3 text-right">
                        <div
                          className="px-3 flex py-1.5 font-bold leading-normal text-center text-white align-middle bg-transparent rounded-lg cursor-pointer text-sm ease-in shadow-md bg-150 bg-gradient-to-tl from-zinc-800 to-zinc-700 dark:bg-gradient-to-tl dark:from-slate-600 dark:to-slate-600 hover:shadow-xs active:opacity-85 tracking-tight-rem bg-x-25"
                          onClick={saveOdontogram}
                        >
                          <div className="fas fa-plus">Guardar</div>
                          {isLoading && <Spinner size="small" />}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex w-80vw">
                <div className="flex py-2 px-4 overflow-scroll">
                  {subActions &&
                    Object.entries(subActions).map(([key, value]) => (
                      <div
                        key={key}
                        onClick={value}
                        className="h-fit min-w-max bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold mx-0.5 py-1 px-2 rounded cursor-pointer transition duration-300 inline-flex items-left"
                      >
                        {key}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rotate-90 sm:rotate-0 md:rotate-0 lg:rotate-0 flex justify-center">
          <div className="" style={{ justifyContent: "center" }}>
            <div className="">
              <div className="flex h-100vh items-center sm:h-50vh md:h-50vh lg:h-50vh">
                <div className="Odontogram">
                  <svg
                    version="1.1"
                    height="100%"
                    className="w-150vw sm:w-60vw md:w-60vw lg:w-60vw sm:h-35vh md:h-35vh lg:h-50vh"
                  >
                    <Teeth
                      start={18}
                      end={11}
                      x={0}
                      y={0}
                      handleChange={handleToothUpdate}
                      data={odontogramData}
                      actionHandler={actionHandler}
                      toothNumberHandler={toothNumberHandler}
                    />
                    <Teeth
                      start={21}
                      end={28}
                      x={210}
                      y={0}
                      handleChange={handleToothUpdate}
                      data={odontogramData}
                      actionHandler={actionHandler}
                      toothNumberHandler={toothNumberHandler}
                    />

                    <Teeth
                      start={55}
                      end={51}
                      x={75}
                      y={45}
                      handleChange={handleToothUpdate}
                      data={odontogramData}
                      actionHandler={actionHandler}
                      toothNumberHandler={toothNumberHandler}
                    />
                    <Teeth
                      start={61}
                      end={65}
                      x={210}
                      y={45}
                      handleChange={handleToothUpdate}
                      data={odontogramData}
                      actionHandler={actionHandler}
                      toothNumberHandler={toothNumberHandler}
                    />

                    <Teeth
                      start={85}
                      end={81}
                      x={75}
                      y={95}
                      handleChange={handleToothUpdate}
                      data={odontogramData}
                      actionHandler={actionHandler}
                      toothNumberHandler={toothNumberHandler}
                    />
                    <Teeth
                      start={71}
                      end={75}
                      x={210}
                      y={95}
                      handleChange={handleToothUpdate}
                      data={odontogramData}
                      actionHandler={actionHandler}
                      toothNumberHandler={toothNumberHandler}
                    />

                    <Teeth
                      start={48}
                      end={41}
                      x={0}
                      y={145}
                      handleChange={handleToothUpdate}
                      data={odontogramData}
                      actionHandler={actionHandler}
                      toothNumberHandler={toothNumberHandler}
                    />
                    <Teeth
                      start={31}
                      end={38}
                      x={210}
                      y={145}
                      handleChange={handleToothUpdate}
                      data={odontogramData}
                      actionHandler={actionHandler}
                      toothNumberHandler={toothNumberHandler}
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div>
          </div>
        </div>
      </main> */}
    </>
  );
}

export default Odontogram;
