import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "./firebase";
import { generateUuid } from "./firebase.helpers";
import { toast } from "react-hot-toast";
import { logError } from "./firebase.errortracker";

const postPraxisWAMessage = async (id, message, modalToggler) => {
  debugger;
  const docRef = doc(db, "praxis", id);
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      let praxis = docSnap.data();
      let praxisWAMessage = {};
      praxisWAMessage.id = generateUuid();
      praxisWAMessage.message = message;
      praxisWAMessage.date = new Date();
      praxis.WAMessage = praxisWAMessage;
      await updateDoc(doc(db, "praxis", id), praxis);
      modalToggler(false);
    } else {
      console.log("User does not exist");
    }
  } catch (error) {
    console.log(error);
  }
};

const getPraxisData = async (userUid) => {
  try {
    const userDocRef = doc(db, "praxis", userUid);
    const userDocSnapshot = await getDoc(userDocRef);
    if (userDocSnapshot.exists()) {
      const praxisData = userDocSnapshot.data();
      return praxisData;
    } else {
      console.log("Praxis document not found");
      return null;
    }
  } catch (err) {
    console.error(err);
    toast.error(`${err.message}`);
  }
};

const updateWASent = async (calendarId) => {
  const calendarRef = doc(db, "calendar", calendarId);
  try {
    const calendarDocSnapshot = await getDoc(calendarRef);

    if (calendarDocSnapshot.exists()) {
      const calendarData = calendarDocSnapshot.data();
      if (!calendarData.contacted) {
        calendarData.contacted = true;
        debugger;
        await updateDoc(calendarRef, calendarData);
        toast.success("Mensaje generado exitosamente");
      }
    } else {
      toast.error("Hubo un error al generar el mensaje");
    }
  } catch (error) {
    toast.error("Hubo un error al generar el mensaje:", error);
    logError(error);
  }

  return false;
};

const addExistingPatient = async (odontoId, praxisId) => {
  try {
    const patientCollectionRef = query(
      collection(db, "patients"),
      where("odontoId", "==", odontoId)
    );
    const querySnapshot = await getDocs(patientCollectionRef);

    if (!querySnapshot.empty) {
      const events = querySnapshot.docs.map((doc) => doc.data());
      events.map(async (e) => {
        e.praxisId.push(praxisId);
        await updateDoc(doc(db, "patients", e.id), e);
      });
      return events[0];
    } else {
      return false;
      // Handle this case as needed
    }
  } catch (error) {
    console.error("Error fetching remote events: ", error);
    throw error;
  }
};

export { postPraxisWAMessage, getPraxisData, updateWASent, addExistingPatient };
