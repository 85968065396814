import React, { useEffect, useState, useRef } from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import {
  deleteEventHandler,
  getCalendarEvents,
} from "../../firebase/firebase.calendar";
import CalendarModal from "./CalendarModal";
import CalendarAdderModal from "./CalendarAdderModal";
import { useSelector } from "react-redux";
import Spinner from "../UI/Spinner";
import Calendar from "../UI/customCalendar/Calendar";
import CalendarViewController from "./CalendarViewController";
import CalendarDateController from "./CalendarDateController";
import ReminderList from "../reminders/ReminderList";
import { getDaysTillAppointment } from "../../utils/calculateAge";

const CalendarHandler = ({ patientsData }) => {
  const [allPatients, setAllPatients] = useState(null);
  const [modal, setModal] = useState(false);
  const [convertedEvents, setConvertedEvents] = useState();
  const [eventsForOverview, setEventsForOverview] = useState();
  const [praxisId, setPraxisId] = useState(null);
  const [currentCalendarDate, setCurrentCalendarDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [aptOTD, setAptOTD] = useState([]);
  const [aptOT, setAptOT] = useState([]);
  const [selected, setSelected] = useState(null);
  const localPraxis = useSelector((state) => state.user?.userDetails);

  const calendarRef = useRef(null);

  useEffect(() => {
    if (patientsData) {
      let filteredUsers = patientsData.map((e, i) => {
        return {
          id: e.id,
          name: e.personalDetails?.name,
          birthdate: e.personalDetails?.birthdate,
          nationality: e.personalDetails?.nationality,
          patientTel: e.personalDetails?.tel,
          value: i,
          praxisId: praxisId,
          praxisName: localPraxis.praxis,
          title: e.personalDetails?.name,
        };
      });
      console.log(filteredUsers);
      setAllPatients(filteredUsers);
    }
  }, [patientsData]);

  useEffect(() => {
    if (localPraxis && praxisId == null) setPraxisId(localPraxis.praxisId);
  }, [localPraxis]);

  const fetchRemote = async () => {
    try {
      if (!isLoading) {
        setIsLoading(true);
        let calendarEntries = await getCalendarEvents(praxisId);
        // Convert Firebase Timestamps to Date objects
        if (calendarEntries) {
          const convertedEvents = calendarEntries.filter((event) => {
            if (event.isActive == undefined || event.isActive) {
              event.start = new Date(event.start.toDate());
              event.end = new Date(event.end.toDate());
              return event;
            }
          });
          setConvertedEvents(convertedEvents);
          setEventsForOverview(convertedEvents);

          const today = [];
          const tomorrow = [];

          convertedEvents.map((e) => {
            let isEqual =
              e?.start.toLocaleDateString() === new Date().toLocaleDateString();
            let isTomorrow = getDaysTillAppointment(e.start, "isTomorrow");
            console.log(isTomorrow);
            if (isEqual) {
              return today.push(e);
            } else if (isTomorrow == 1) {
              return tomorrow.push(e);
            }
          });
          setIsLoading(false);
          setAptOTD(today);
          setAptOT(tomorrow);
          return new Promise((res) => {
            setTimeout(() => {
              res(convertedEvents);
            }, 800);
          });
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching remote events: ", error);
      throw error;
    }
  };

  const handleDelete = async (deletedId) => {
    await deleteEventHandler(deletedId, praxisId);
    return new Promise((res) => {
      setTimeout(() => {
        res(deletedId);
      }, 500);
    });
  };

  let viewOptions = [
    {
      title: "Calendario",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
          />
        </svg>
      ),
    },
    {
      title: "Recordatorios",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
          />
        </svg>
      ),
    },
  ];

  return (
    <>
      <div className="">
        {allPatients && praxisId ? (
          <>
            <div className="">
              <div>
                <div>
                  <div className="flex justify-between p-3 rounded-sm border border-gray-200 w-full">
                    <div className="p-1">
                      <div className="flex justify-center">
                        <div
                          className="m-1 p-1 flex w-full text-white rounded-md w-10vw justify-center"
                          style={{ backgroundColor: "#253141" }}
                        >
                          <div className="px-1 font-bold">+</div>
                          <div
                            className="px-1 text-ellipsis overflow-hidden"
                            onClick={() =>
                              calendarRef.current.scheduler.triggerDialog(
                                (scheduler) => (
                                  <CalendarAdderModal
                                    scheduler={scheduler}
                                    refreshCalendar={fetchRemote}
                                    convertedEvents={convertedEvents}
                                    setNewConvertedEvents={setEventsForOverview}
                                    patients={allPatients}
                                    calendarRef={calendarRef}
                                  />
                                )
                              )
                            }
                          >
                            Cita
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between w-3/4 p-1">
                      <CalendarDateController calendarRef={calendarRef} />
                      <CalendarViewController calendarRef={calendarRef} />
                    </div>

                    <div className={`m-1 p-1 flex text-white rounded-md`}></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col-reverse md:flex-row lg:flex-row lg:justify-evenly w-full">
              <div
                className={`flex justify-center ${
                  selected == "Calendario" && "hidden"
                } `}
              >
                <div className="flex w-11/12 lg:w-full md:w-full flex-col-reverse sm:flex-col md:flex-col lg:flex-col">
                  <Calendar calendarRef={calendarRef} />
                  <ReminderList
                    today={aptOTD}
                    setToday={setAptOTD}
                    tomorrow={aptOT}
                    setTomorrow={setAptOT}
                    praxisId={praxisId}
                  />
                </div>
              </div>

              <div
                className={`m-3 md:w-2/3 lg:w-2/3 ${
                  selected == "Recordatorios" && "hidden"
                }`}
              >
                <div style={{ overflow: "scroll" }} className="h-[65vh]">
                  <Scheduler
                    ref={calendarRef}
                    customEditor={(scheduler) => (
                      <CalendarAdderModal
                        scheduler={scheduler}
                        refreshCalendar={fetchRemote}
                        convertedEvents={convertedEvents}
                        setNewConvertedEvents={setEventsForOverview}
                        patients={allPatients}
                        calendarRef={calendarRef}
                      />
                    )}
                    getRemoteEvents={fetchRemote}
                    navigation={false}
                    disableViewNavigator={true}
                    viewerTitleComponent={(scheduler) => (
                      <div>{scheduler?.patientName}</div>
                    )}
                    onDelete={handleDelete}
                    view="week"
                    height={350}
                    week={{
                      startHour: 7,
                      endHour: 20,
                      step: 15,
                    }}
                    day={{
                      startHour: 7,
                      endHour: 22,
                      step: 60,
                      navigation: true,
                    }}
                    onSelectedDateChange={(e) => setCurrentCalendarDate(e)}
                  />
                </div>
              </div>

              <div className="flex lg:hidden md:hidden flex-row py-2 px-4 sm:px-2 lg:px-2 overflow-x-auto justify-between">
                <div className="flex w-full justify-around lg:mr-[6rem] lg:ml-[3rem] md:mr-[6rem] md:ml-[3rem] divide-x">
                  {viewOptions &&
                    viewOptions.map((e) => (
                      <div
                        className="text-large font-bold tracking-tight text-gray-900 px-3"
                        onClick={() => {
                          setSelected(e.title);
                        }}
                        key={e.title}
                      >
                        <div className="flex justify-center items-center">
                          <div className="p-1 text-xs"> {e.icon}</div>
                          <div className="p-1 text-xs">{e.title}</div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              {!modal && (
                <div>
                  <CalendarModal state={modal} stateSetter={setModal} />
                </div>
              )}
            </div>
          </>
        ) : (
          <div
            className="flex items-center justify-center"
            style={{ height: "90vh" }}
          >
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};

export default CalendarHandler;
