import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Pill from "../UI/Pill";
import EditAndDelete from "../UI/EditAndDelete";

const FinancesRow = ({
  reason,
  date,
  description,
  hasBeenPaid,
  mode,
  url,
  balance,
  price,
  patient,
  payments,
}) => {
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    console.log(payments);
  }, [payments]);

  const toggleMore = () => setShowMore(!showMore);

  return (
    <>
      <div
        className={`grid grid-cols-12 gap-0 ${
          mode === "darkmode"
            ? `odd:dark:bg-gray-900 even:dark:bg-gray-800 dark:border-gray-700`
            : "odd:bg-white even:bg-gray-50 border-b text-gray-700"
        }`}
      >
        <div
          className="px-2 flex items-center py-4 font-medium whitespace-pre-wrap col-span-2"
          onClick={toggleMore}
        >
          {!showMore ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 15.75 7.5-7.5 7.5 7.5"
              />
            </svg>
          )}

          <div
            className={`flex items-center ${
              mode === "darkmode" ? "dark:text-white" : "text-sky-500"
            } '`}
          >
            <div>{reason}</div>
          </div>
        </div>
        <div
          onClick={toggleMore}
          className={`col-span-4 flex items-center ${
            mode === "darkmode" ? "dark:text-white" : "text-sky-500"
          } ' px-6 py-4 font-medium whitespace-pre-wrap`}
        >
          <div>{description}</div>
        </div>
        <div
          className="py-4 flex items-center col-span-1 whitespace-pre-wrap"
          onClick={toggleMore}
        >
          <div>
            {date && date?.seconds !== undefined
              ? new Date(date.seconds * 1000).toISOString().split("T")[0]
              : date.split("T")[0]}
          </div>
        </div>
        <div
          className="px-6 flex items-center py-4 col-span-1"
          onClick={toggleMore}
        >
          <div>
            {price
              ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : ""}
          </div>
        </div>
        <div
          className="px-6 py-4 flex items-center col-span-1.5"
          onClick={toggleMore}
        >
          <div>
            {hasBeenPaid ? (
              <Pill
                text="Pagado"
                type="progress"
                balance={balance}
                price={price}
              />
            ) : (
              <Pill
                text="Pendiente"
                type="progress"
                balance={balance}
                price={price}
              />
            )}
          </div>
        </div>
        <div
          className="px-6 py-4 flex items-center col-span-1"
          onClick={toggleMore}
        >
          <div>
            {balance
              ? balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : ""}
          </div>
        </div>
        <div
          className="px-6 py-4 flex items-center col-span-1"
          onClick={toggleMore}
        >
          <div>{patient}</div>
        </div>

        <div className="px-6 py-4 flex items-center col-auto">
          <div>
            <EditAndDelete
              editHandler={() => console.log("edit")}
              deleteHandler={() => console.log("delete")}
            />
          </div>
        </div>
      </div>

      {showMore && payments && (
        <>
          <div class="bg-gray-200 text-gray-700 text-xs text-gray-700 sticky top-0 uppercase bg-gray-50 dark:bg-green-700 dark:text-gray-200">
            <div class="grid grid-cols-12 gap-0">
              <div class="p-3 col-span-2 flex justify-center border-b border-gray-300">
                {/* Pagos --{reason} */}
              </div>
              <div class="p-3 col-span-5 flex justify-left border-b border-gray-300">
                Descripción del Pago
              </div>
              <div class="p-3 col-span-2 flex justify-left border-b border-gray-300">
                Fecha
              </div>
              <div class="p-3 col-span-1 flex justify-center border-b border-gray-300">
                Pago
              </div>
              <div class="p-3 col-span-1 flex justify-center border-b border-gray-300">
                Factura
              </div>
              <div class="p-3 col-span-1 flex justify-center border-b border-gray-300"></div>
              {/* <div class="p-2 col-span-1 flex justify-center border-b border-gray-300"></div> */}
              {/* <div class="p-2 col-span-1 flex justify-center border-b border-gray-300"></div> */}
            </div>
          </div>
          <div>
            {payments &&
              payments.map((e) => {
                return (
                  <div
                    onClick={() => {
                      setShowMore(!showMore);
                    }}
                    className={`grid grid-cols-12 gap-0 odd:dark:bg-green-800 even:dark:bg-green-900 dark:border-gray-700`}
                  >
                    <div
                      className={`${
                        mode === "darkmode" ? "dark:text-white" : "text-sky-500"
                      } ' px-6 py-4 font-medium whitespace-pre-wrap col-span-2 flex items-center`}
                    >
                      <div>📈 {reason}</div>
                    </div>
                    <div
                      className={`col-span-5 ${
                        mode === "darkmode" ? "dark:text-white" : "text-sky-500"
                      } ' px-6 py-4 font-medium whitespace-pre-wrap flex items-center text-white`}
                    >
                      <div>{e.description}</div>
                    </div>
                    <div className="px-2 py-4 col-span-2 whitespace-pre-wrap flex items-center text-white">
                      <div>
                        {e && e.date?.seconds !== undefined
                          ? new Date(e.date.seconds * 1000)
                              .toISOString()
                              .split("T")[0]
                          : date.split("T")[0]}
                      </div>
                    </div>
                    <div className="px-6 py-4 col-span-1 flex items-center text-white">
                      <div>
                        {e && e.amount
                          ? e.amount
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : ""}
                      </div>
                    </div>
                    <div className="px-6 py-4 col-span-1 flex items-center text-white">
                      <div>{e.invoice}</div>
                    </div>
                    {/* <div className="px-6 py-4 col-span-1" colSpan={1}></div> */}
                    {/* <div className="px-6 py-4 col-span-1" colSpan={1}></div> */}
                    <div className="px-6 py-4 col-span-1 flex items-center">
                      <div>
                        <EditAndDelete
                          editHandler={() => console.log("edit")}
                          deleteHandler={() => console.log("delete")}
                          textColor="text-white"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </>
  );
};

export default FinancesRow;
