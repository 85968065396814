import { useEffect, useRef, useState } from "react";
import {
  createRecord,
  updateRecordsById,
} from "../../firebase/firebase.patientHistory";
import Spinner from "../UI/Spinner";
import DatePicker from "react-datepicker";
import { DatePickerIcon } from "./DatePickerIcon";
import { deserializeDate } from "../../utils/dateFormatter";

const Modal = ({
  modalState,
  modalSetter,
  historyToUpdate,
  modalTitle,
  modalTitleSetter,
  allRecords,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [date, setDate] = useState(new Date());
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (modalState !== open) {
      setOpen(modalState);
    }
  }, [open]);

  useEffect(() => {
    console.log(historyToUpdate);

    if (historyToUpdate.selectedHistory !== 0) {
      setType(historyToUpdate.selectedHistory.type);
      setTitle(historyToUpdate.selectedHistory.title);
      setSubtitle(historyToUpdate.selectedHistory.subtitle);
      setDate(deserializeDate(historyToUpdate.selectedHistory.date));
    } else {
      setDate(new Date());
    }
  }, [historyToUpdate]);

  const updateHistory = async () => {
    if (historyToUpdate.selectedHistory !== 0) {
      await updateRecordsById(
        historyToUpdate.userId,
        historyToUpdate.selectedHistory.id,
        {
          type: type,
          date: date,
          title: title,
          subtitle: subtitle,
          id: historyToUpdate.selectedHistory.id,
          practorId: historyToUpdate.practorId,
          practorName: historyToUpdate.practorName,
          patientId: historyToUpdate.userId,
          praxisId: historyToUpdate.praxisId,
          praxisName: historyToUpdate.praxisName,
        },
        historyToUpdate.historyStateSetter,
        allRecords
      );
      modalSetter(false);
      modalTitleSetter("");
    } else if (historyToUpdate.selectedHistory === 0) {
      await createRecord(
        {
          type: type,
          date: date,
          title: title,
          subtitle: subtitle,
          id: 0,
          practorId: historyToUpdate.practorId,
          practorName: historyToUpdate.practorName,
          patientId: historyToUpdate.userId,
          praxisId: historyToUpdate.praxisId,
          praxisName: historyToUpdate.praxisName,
        },
        allRecords,
        historyToUpdate.historyStateSetter,
        historyToUpdate.modalState
      );
      modalTitleSetter("");
    }
  };

  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
        style={{ zIndex: "10000" }}
      >
        <div className="relative my-6 mx-auto w-60vw">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-12">
            {/*header*/}
            {
              <>
                <div className="flex items-center w-full justify-center p-5 m-2 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {" "}
                    {`${modalTitle} Historial`}
                  </h3>
                </div>
                <div className="px-5 pb-5 mt-3">
                  <div className="mt-2">
                    <div className="flex flex-col">
                      <input
                        className="mb-4 text-sm leading-normal dark:text-black"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      />
                      <div className="mb-5 flex items-center">
                        <label
                          for="base-input"
                          className="block mr-2 text-sm font-medium text-gray-900 w-3/12"
                        >
                          Categoria
                        </label>
                        <input
                          type="text"
                          id="base-input"
                          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          onChange={(e) => setTitle(e.target.value)}
                          value={title}
                        />
                      </div>

                      <div className="mb-5 flex items-center">
                        <label
                          for="large-input"
                          className="block mr-2 text-sm font-medium text-gray-900 w-3/12"
                        >
                          Descripción:{" "}
                        </label>
                        <textarea
                          type="text"
                          id="large-input"
                          className="block w-full whitespace-pre-wrap resize p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:ring-blue-500 focus:border-blue-500"
                          value={subtitle}
                          onChange={(e) => setSubtitle(e.target.value)}
                        />
                      </div>
                      <div className="mb-5 flex items-center">
                        <label
                          for="large-input"
                          className="block text-sm font-medium text-gray-900 w-1/5 mr-1"
                        >
                          Fecha:{" "}
                        </label>
                        <div>
                          <DatePicker
                            selected={date}
                            onChange={(date) => setDate(date)}
                            showTimeSelect
                            dateFormat={"dd/MM/yyyy HH:mm"}
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pl-10 p-2.5"
                            popperPlacement="top"
                            showIcon
                            icon={DatePickerIcon}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row-reverse"></div>
                </div>

                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => modalSetter(false)}
                    ref={cancelButtonRef}
                  >
                    Cerrar
                  </button>
                  <div className="flex bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                    <button className="" type="button" onClick={updateHistory}>
                      Guardar
                    </button>
                    {isLoading && (
                      <div className="ml-2">
                        <Spinner size="small" fill="fill-emerald-800" />
                      </div>
                    )}
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default Modal;
