import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ClientViewContext } from "../../context/clientView/clientView.context";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

const HeaderActions = ({ title }) => {
  const [selected, setSelected] = useState(null);
  const { dispatch } = useContext(ClientViewContext);

  let viewOptions = [
    {
      title: "Ficha",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            classnamstrokelinecape="round"
            strokeLinejoin="round"
            d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
          />
        </svg>
      ),
    },
    {
      title: "Odontograma",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="0 0 40 40"
          xmlSpace="preserve"
          width="40"
          height="40"
          className="w-4 h-4"
        >
          <path
            d="M25.76 37.5c-1.966 0-2.26-2.192-2.26-3.5l.004-.818c.021-3.23.053-8.113-1.77-9.947-.484-.488-1.068-.735-1.734-.735s-1.25.247-1.734.734c-1.822 1.834-1.79 6.717-1.77 9.947L16.5 34c0 1.308-.294 3.5-2.261 3.5-2.255 0-2.99-3.53-3.841-7.618-.275-1.323-.561-2.691-.916-4.012-.552-2.004-1.488-3.874-2.394-5.683-1.33-2.659-2.588-5.171-2.588-7.901C4.5 5.701 7.843 2.5 14.72 2.5c1.33 0 3.656.37 4.866.902l.09.039.102.003c.231 0 .398-.043.431-.052.04-.012 3.036-.893 4.698-.893 3.438 0 6.238.996 8.101 2.879 1.663 1.682 2.524 4.068 2.492 6.901 0 2.734-1.257 5.247-2.588 7.907-.905 1.809-1.842 3.679-2.394 5.68-.36 1.322-.648 2.701-.927 4.036-.817 3.908-1.587 7.598-3.831 7.598z"
            fill="#FFF"
          />
          <path
            d="M24.906 3c3.301 0 5.979.944 7.746 2.731 1.589 1.607 2.378 3.808 2.347 6.555 0 2.611-1.232 5.072-2.536 7.678-.916 1.83-1.863 3.723-2.428 5.773-.364 1.335-.654 2.722-.934 4.063-.773 3.702-1.503 7.2-3.342 7.2C24.305 37 24 35.369 24 34c0-.249.002-.523.004-.815.027-4.211-.098-8.475-1.915-10.303A2.893 2.893 0 0 0 20 22c-.793 0-1.516.305-2.089.882-1.817 1.828-1.942 6.092-1.915 10.303.002.292.004.566.004.815 0 1.369-.305 3-1.76 3-1.849 0-2.579-3.508-3.352-7.222-.277-1.329-.563-2.704-.923-4.044-.565-2.048-1.512-3.94-2.428-5.77C6.232 17.358 5 14.897 5 12.286 5 6.037 8.179 3 14.72 3c1.279 0 3.545.368 4.665.859l.215.085h.21c.255 0 .471-.054.55-.075.817-.242 3.227-.869 4.546-.869m0-1c-1.756 0-4.83.91-4.83.91s-.125.034-.266.034h-.022C18.531 2.392 16.139 2 14.72 2 7.373 2 4 5.65 4 12.286 4 17.057 7.664 21.155 9 26c1.484 5.516 1.752 12 5.24 12 1.855 0 2.76-1.614 2.76-4 0-3.355-.318-11 3-11s3 7.645 3 11c0 2.386.906 4 2.759 4 3.488 0 3.757-6.559 5.241-12 1.336-4.845 4.999-8.944 4.999-13.714C36.074 5.65 31.544 2 24.906 2z"
            fill="#788B9C"
          />
          <path
            d="M23.5 6.5s-3-4-8.5-4"
            fill="none"
            stroke="#788B9C"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
          />
        </svg>
      ),
    },
    {
      title: "Legales",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            classnamstrokelinecape="round"
            strokeLinejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
          />
        </svg>
      ),
    },
    {
      title: "Pagos",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
          />
        </svg>
      ),
    },
    {
      title: "Historial",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0 1 20.25 6v12A2.25 2.25 0 0 1 18 20.25H6A2.25 2.25 0 0 1 3.75 18V6A2.25 2.25 0 0 1 6 3.75h1.5m9 0h-9"
          />
        </svg>
      ),
    },
  ];

  const setFicha = useCallback(
    () => dispatch({ type: "SET_ClientView_Ficha" }),
    [dispatch]
  );
  const setLegal = useCallback(
    () => dispatch({ type: "SET_ClientView_Legal" }),
    [dispatch]
  );
  const setPagos = useCallback(
    () => dispatch({ type: "SET_ClientView_Pagos" }),
    [dispatch]
  );
  const setHistorial = useCallback(
    () => dispatch({ type: "SET_ClientView_History" }),
    [dispatch]
  );
  const setOdontograma = useCallback(
    () => dispatch({ type: "SET_ClientView_Odontograma" }),
    [dispatch]
  );

  useEffect(() => {
    switch (selected) {
      case "Ficha":
        return setFicha();
      case "Legales":
        return setLegal();
      case "Pagos":
        return setPagos();
      case "Historial":
        return setHistorial();
      case "Odontograma":
        return setOdontograma();
    }
  }, [selected]);

  return (
    <header className="flex flex-col bg-white border border-gray-100 rounded-t-lg">
      <div className="flex flex-row py-2 px-4 sm:px-2 lg:px-2 overflow-x-auto justify-between">
        <Link
          to="/dashboard"
          type="button"
          className="flex items-center px-2 font-medium tracking-wide text-black capitalize rounded-md  hover:bg-gray-200 hover:fill-current hover:text-white-600  focus:outline-none  transition duration-300 transform active:scale-95 ease-in-out"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-arrow-left"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5 12l14 0" />
            <path d="M5 12l6 6" />
            <path d="M5 12l6 -6" />
          </svg>
        </Link>
        <div className="flex w-full justify-between lg:mr-[6rem] lg:ml-[3rem] md:mr-[6rem] md:ml-[3rem] divide-x">
          {viewOptions &&
            viewOptions.map((e) => (
              <div
                className="text-large font-bold tracking-tight text-gray-900 px-3"
                onClick={() => {
                  setSelected(e.title);
                }}
                key={e.title}
              >
                <div className="flex justify-center items-center">
                  <div className="p-1 text-xs"> {e.icon}</div>
                  <div className="p-1 text-xs">{e.title}</div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </header>
  );
};

export default HeaderActions;
