import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "./firebase";
import { toast } from "react-hot-toast";
import { currentRawDate } from "./firebase.helpers";

const clientPoster = async (dbName, id, data, setter) => {
  try {
    await setDoc(doc(db, dbName, id), data);
    toast.success(`El paciente ${id} ha sido agregado exitosamente`);
    setter(true);
  } catch (e) {
    toast.error(
      "Hubo un problema al agregar un paciente. Porfavor intentelo nuevamente."
    );
    setter(false);
  }
};

const clientUpdater = async (dbName, id, data, setter) => {
  try {
    await updateDoc(doc(db, dbName, id), data);
    toast.success("El paciente ha sido editado exitosamente");
    setter(true);
  } catch (e) {
    console.log(e);
    toast.error(
      "Hubo un problema al editar el paciente. Porfavor intentelo nuevamente."
    );
    setter(false);
  }
};

const getData = async (praxisId) => {
  try {
    const data = [];
    const q = query(
      collection(db, "patients"),
      where("praxisId", "array-contains", praxisId)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });

    return data;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error; // Re-throw the error if needed
  }
};

const getDataByUserId = async (id) => {
  const docRef = doc(db, "patients", id);
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("User does not exist");
    }
  } catch (error) {
    console.log(error);
  }
  return;
};

const saveClientLegals = async (userId, contractData) => {
  const userDocRef = doc(db, "patients", userId);
  try {
    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      contractData.date = `${currentRawDate.getDate()}-${
        currentRawDate.getMonth() + 1
      }-${currentRawDate.getFullYear()}`;
      data.legals = contractData;
      await updateDoc(doc(db, "patients", userId), data);
      toast.success("Contrato guardado exitosamente");
    } else {
      console.log("User does not exist");
    }
  } catch (err) {
    toast.error(
      "Hubo un problema al editar el paciente. Porfavor intentelo nuevamente."
    );
    console.log(err);
  }
};

const getPatientByPraxisId = async (praxisId, patientId) => {
  try {
    // toast.loading("Buscando Paciente..");
    const calendarCollectionRef = query(
      collection(db, "patients"),
      where("praxisId", "array-contains", praxisId),
      where("id", "==", patientId)
    );
    const querySnapshot = await getDocs(calendarCollectionRef);
    if (!querySnapshot.empty) {
      // Process the data
      const events = querySnapshot.docs.map((doc) => doc.data());
      // toast.dismiss();
      return events[0];
    } else {
      toast.error("No tiene suficientes permisos");
    }
  } catch (error) {
    console.error("Error fetching remote patient: ", error);
    throw error;
  }
};

const checkIfExists = async (ci) => {
  try {
    const calendarCollectionRef = query(
      collection(db, "patients"),
      where("odontoId", "==", ci)
    );
    const querySnapshot = await getDocs(calendarCollectionRef);
    if (!querySnapshot.empty) {
      const events = querySnapshot.docs.map((doc) => doc.data());
      console.log(events);
      return events;
    } else {
      toast.error("No existe un paciente con este ODONTOID");
      return false;
    }
  } catch (error) {
    console.error("Error fetching remote patient: ", error);
    throw error;
  }
};

export {
  clientPoster,
  clientUpdater,
  getData,
  getDataByUserId,
  saveClientLegals,
  getPatientByPraxisId,
  checkIfExists,
};
