import React from "react";

const CalendarViewController = ({ calendarRef }) => {
  return (
    <div className="flex w-full">
      <div className="inline-flex rounded-sm shadow-sm" role="group">
        <div
          type="button"
          className={`px-4 py-2 text-sm font-medium text-gray-900 ${
            calendarRef?.current?.scheduler?.view == "day"
              ? "bg-gray-100"
              : "bg-white"
          } border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 rounded-sm`}
          onClick={() => {
            calendarRef?.current?.scheduler?.handleState("day", "view");
          }}
        >
          Día
        </div>
        <div
          type="button"
          className={`px-4 py-2 text-sm font-medium text-gray-900 ${
            calendarRef?.current?.scheduler?.view == "week"
              ? "bg-gray-100"
              : "bg-white"
          } border-t border-b border-gray-200 hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 rounded-sm`}
          onClick={() => {
            calendarRef?.current?.scheduler?.handleState("week", "view");
          }}
        >
          Semana
        </div>
        <div
          type="button"
          className={`px-4 py-2 text-sm font-medium text-gray-900 ${
            calendarRef?.current?.scheduler?.view == "month"
              ? "bg-gray-100"
              : "bg-white"
          } border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 rounded-sm`}
          onClick={() => {
            calendarRef?.current?.scheduler?.handleState("month", "view");
          }}
        >
          Mes
        </div>
      </div>
    </div>
  );
};

export default CalendarViewController;
