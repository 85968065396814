import React from "react";

const FinanceControllers = ({ setSelectedCategory, currentCategory }) => {
  return (
    <div className="flex items-center" style={{ justifyContent: "flex-start" }}>
      <button
        value="all"
        className={`px-4 py-2 text-sm font-medium text-gray-900 ${
          currentCategory == "all" ? "bg-gray-100" : "bg-white"
        } border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 rounded-sm`}
        onClick={(e) => setSelectedCategory(e.target.value)}
      >
        Todos
      </button>
      <button
        value="pending"
        onClick={(e) => setSelectedCategory(e.target.value)}
        className={`px-4 py-2 text-sm font-medium text-gray-900 ${
          currentCategory == "pending" ? "bg-gray-100" : "bg-white"
        } border-t border-b border-gray-200 hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 rounded-sm`}
      >
        Pendientes
      </button>
      <button
        value="paid"
        onClick={(e) => setSelectedCategory(e.target.value)}
        className={`px-4 py-2 text-sm font-medium text-gray-900 ${
          currentCategory == "paid" ? "bg-gray-100" : "bg-white"
        } border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 rounded-sm`}
      >
        Pagados
      </button>
    </div>
  );
};

export default FinanceControllers;
