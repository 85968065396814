import React, { useState, useEffect } from "react";
import PaymentModal from "../../../UI/PaymentModal";
import DeleteButton from "../../../UI/DeleteButton";
import {
  deletePaymentItem,
  allPaymentsByUIdAndPId,
} from "../../../../firebase/firebase.payment";
import LineBreaker from "../../../UI/LineBreaker";
import PaymentsTable from "./PaymentsTable";
import { useSelector } from "react-redux";

const Payments = ({ data }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [allPayments, setAllPayments] = useState(false);
  const localPraxis = useSelector((state) => state.user?.userDetails);

  useEffect(() => {
    // debugger;
    console.log(data);
    if (data.services !== null) {
      // setAllPayments(data.services);
    } else {
      setAllPayments([]);
    }
  }, [data.services]);

  useEffect(() => {
    if (!allPayments) {
      const getAllPayments = async () => {
        debugger;
        const payments = await allPaymentsByUIdAndPId(
          localPraxis.praxisId,
          data.id
        );
        setAllPayments(payments);
      };
      getAllPayments();
    }
  }, []);

  return (
    <>
      <div className="">
        <div className="flex mb-4 " style={{ justifyContent: "flex-start" }}>
          <button
            className="m-2 text-xs font-bold leading-tight uppercase dark:text-black text-slate-500"
            value="all"
            onClick={(e) => setSelectedCategory(e.target.value)}
          >
            Movimientos
          </button>
          <button
            className="m-2 text-xs font-bold leading-tight uppercase dark:text-black text-slate-500"
            value="pending"
            onClick={(e) => setSelectedCategory(e.target.value)}
          >
            Pendientes
          </button>
          <button
            className="m-2 text-xs font-bold leading-tight uppercase dark:text-black text-slate-500"
            value="paid"
            onClick={(e) => setSelectedCategory(e.target.value)}
          >
            Pagados
          </button>
        </div>
        <div className="flex justify-end m-2">
          <div
            className="m-1 p-1 flex text-white rounded-md"
            style={{ backgroundColor: "#253141" }}
            onClick={() => setOpenModal(true)}
          >
            <div className="px-1 font-bold">+</div>
            <div className="px-1">Agregar Acción</div>
          </div>
        </div>
        {allPayments && selectedCategory === "all" && (
          <PaymentsTable userData={allPayments} setOpenModal={setOpenModal} />
        )}
        {allPayments && selectedCategory === "pending" && (
          <PaymentsTable userData={allPayments} setOpenModal={setOpenModal} />
        )}
        {allPayments && selectedCategory === "paid" && (
          <PaymentsTable userData={allPayments} setOpenModal={setOpenModal} />
        )}
      </div>

      {openModal && (
        <PaymentModal
          data={data}
          modalState={openModal}
          modalSetter={setOpenModal}
          allPaymentsSetter={setAllPayments}
          allPayments={allPayments}
        />
      )}
    </>
  );
};

export default Payments;
