import React, { useEffect, useState } from "react";
import Tooth from "./Tooth";

function Teeth({
  start,
  end,
  x,
  y,
  handleChange,
  data,
  actionHandler,
  toothNumberHandler,
}) {
  const [userData, setUserData] = useState(null);
  let tooths = getArray(start, end);

  useEffect(() => {
    if (data !== null && data !== undefined && data.odontograma !== null) {
      setUserData(data);
    }
  }, [data]);

  return (
    //
    <>
      {userData && (
        // <div className="m-1">
        <g transform="scale(1.5)" id="gmain">
          {tooths.map((i) => (
            <Tooth
              onChange={handleChange}
              key={i}
              number={i}
              positionY={y}
              positionX={Math.abs((i - start) * 25) + x}
              toothData={userData[i]}
              actionHandler={actionHandler}
              toothNumberHandler={toothNumberHandler}
            />
          ))}
        </g>
        // </div>
      )}
    </>
    // </div>
  );
}

function getArray(start, end) {
  if (start > end) return getInverseArray(start, end);

  let list = [];
  for (var i = start; i <= end; i++) {
    list.push(i);
  }

  return list;
}

function getInverseArray(start, end) {
  let list = [];

  for (var i = start; i >= end; i--) {
    list.push(i);
  }

  return list;
}

export default Teeth;
