import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./components/login/Login";
import ErrorPage from "./components/errorPage/ErrorPage";
import Home from "./components/home/Home";
import { Toaster } from "react-hot-toast";
import { ClientViewProvider } from "./context/clientView/clientView.provider";
import CalendarHome from "./components/calendar/CalendarHome";
import store from "./store/store";
import { Provider } from "react-redux";
import PrivateRoute from "./components/privateRoute/PrivateRoute";
import Register from "./components/register/Register";
import Admin from "./components/admin/Admin";
import UserProfile from "./components/admin/UserProfile";
import ErrorsAdmin from "./components/admin/errors/ErrorsAdmin";
import PatientOverview from "./components/patient/patientOverview/PatientOverview";
import Finances from "./components/finances/Finances";

const router = createBrowserRouter([
  { path: "/", element: <App />, errorElement: <ErrorPage /> },
  { path: "/login", element: <Login />, errorElement: <ErrorPage /> },
  {
    path: "/register",
    element: <PrivateRoute element={<Register />} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/dashboard",
    element: <PrivateRoute element={<Home />} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/calendario",
    element: <PrivateRoute element={<CalendarHome />} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/finanzas",
    element: <PrivateRoute element={<Finances />} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin",
    element: <PrivateRoute element={<Admin />} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/errors",
    element: <PrivateRoute element={<ErrorsAdmin />} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin/:id",
    element: <PrivateRoute element={<UserProfile />} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/:id",
    element: <PrivateRoute element={<PatientOverview />} />,
    errorElement: <ErrorPage />,
  },
]);

console.log(
  "Hello Developer\nWanna collab?\nWrite us at: odontostarktech@gmail.com"
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ClientViewProvider>
        <Toaster position="top-right" />
        <RouterProvider router={router} />
      </ClientViewProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
