const dateFormatter = (date) => {
  return date.toISOString().split("T")[0];
};

const dateTimeFormatter = (date) => {
  let rawDate = date.toISOString().split("T");
  return rawDate[0] + " - " + rawDate[1];
};

const timeFormatter = (date) => {
  let timeString;
  if (typeof date !== "string") {
    timeString = date.toISOString().split("T")[1];
  } else {
    timeString = date.split("T")[1];
  }
  const regex = /^(\d{2}:\d{2})/;
  const match = timeString.match(regex);
  if (match) {
    return match[1]; // Output: "07:45"
  } else {
    console.log("No match found.");
  }
  //   let rawDate = date.toISOString().split("T").split(" ");
  //   return rawDate[1];
};

const deserializeDate = (date) => {
  const dateString = new Date(date?.seconds * 1000);
  return dateString;
};

export { dateFormatter, dateTimeFormatter, timeFormatter, deserializeDate };
